import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../../layout/Header";
import HeadingSection from "../TransactionHeadingSection";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getmerchantschema, transactionRegistration } from "../../campaigns/services";
import { getCampaigns, clearCampaigns } from "../../../../actions/campaigns";
import JSONPretty from "react-json-pretty";
import { connect } from "react-redux";
import SelectValidator from "../../../partial/SelectValidator";
import { ValidatorForm } from "react-form-validator-core";
import { extract10DigitNumber, validEmail } from "../../../../utils/helper";
import swal from "sweetalert";

let token;
const TransactionAPI = ({ getCampaigns, clearCampaigns, Campaigns }) => {
    const [state, setState] = useState({
        responseCopied: false,
        requestCopied: false,
        loading: false,
        responseText: " ",
        err: false,
        errMsg: "",
        success: false,
        successMsg: "",
    });
    const [disable, setDisable] = useState(true);
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState();
    const [option, setOption] = useState([]);
    const [merchantSchema, setMerchantSchema] = useState([]);
    const [requestText, setRequestText] = useState(`{
    	"customer" : {
    	    "mobile" : "9875432145"
    	}
    }`);

    const fetch = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        await getCampaigns(token?.access_token, id);
        setLoader(false);
    };

    const fetchCampaigns = async () => {
        let temp = [];
        let flag = 1;
        for (let i = 0; i < Campaigns?.data.length; i++) {
            if (Campaigns?.data[i] && new Date().getTime() <= new Date(Campaigns?.data[i].endDateTime).getTime()) {
                temp.push({
                    label: Campaigns?.data[i].name,
                    value: Campaigns?.data[i].code,
                    index: flag,
                });
            }
            flag += 1;
        }
        temp.reverse();
        setOption(temp);
    };
    useEffect(() => {
        fetch();
    }, []); // eslint-disable-line
    useEffect(() => {
        fetchCampaigns();
    }, [Campaigns]); // eslint-disable-line

    const { requestCopied, responseCopied, loading, responseText, err, errMsg, success, successMsg } = state;
    const handleCopy = (copy) => {
        copy === "request" ? setState({ requestText, responseText, requestCopied: true }) : setState({ responseText, requestText, responseCopied: true });
        setTimeout(() => {
            copy === "request" && setState({ requestText, responseText, requestCopied: "" });
        }, 1000);
        setTimeout(() => {
            copy === "response" && setState({ responseText, requestText, responseCopied: "" });
        }, 1000);
    };
    const onSubmit = () => {};
    const handleAccessToken = async () => {
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        token = tempToken?.access_token;
        setState({ success: true, successMsg: "Token generated successfully" });
    };
    useEffect(() => {
        let tokenSuccess, error;
        if (success) {
            tokenSuccess = setTimeout(() => {
                setState({ success: false, successMsg: "" });
            }, 2000);
        }
        if (err) {
            error = setTimeout(() => {
                setState({ err: false, errMsg: "" });
            }, 2000);
        }
        return () => {
            clearTimeout(tokenSuccess);
            clearTimeout(error);
        };
    }, [success, err]);

    const createTransaction = async () => {
        const transFun = async (body) => {
            body = JSON.stringify(body);
            setState({ loading: true });
            setDisable(true);
            const transactionReg = await transactionRegistration(token, body);
            setState({ responseText: JSON.stringify(transactionReg), loading: false });
            setDisable(false);
            setData(false);
        };
        try {
            let body = JSON.parse(document.getElementById("api").value);
            const { mobile, email } = body;
            let tempMobile = mobile && extract10DigitNumber(mobile);
            let tempEmail = email && validEmail(email);

            if (token) {
                if (mobile !== undefined || email !== undefined) {
                    if (mobile && !email) {
                        if (tempMobile !== null) {
                            try {
                                transFun(body);
                            } catch (error) {
                                swal({
                                    title: " Oops!",
                                    text: error,
                                    icon: "error",
                                    button: "oh no!",
                                    timer: 5000,
                                });
                            }
                        } else {
                            setState({ err: true, errMsg: "Please enter a valid mobile number" });
                        }
                    } else if (!mobile && email) {
                        if (tempEmail) {
                            try {
                                transFun(body);
                            } catch (error) {
                                swal({
                                    title: " Oops!",
                                    text: error,
                                    icon: "error",
                                    button: "oh no!",
                                    timer: 5000,
                                });
                            }
                        } else {
                            setState({ err: true, errMsg: "Please enter a valid mail" });
                        }
                    } else {
                        if (tempEmail != null && tempMobile != null) {
                            try {
                                transFun(body);
                            } catch (error) {
                                swal({
                                    title: " Oops!",
                                    text: error,
                                    icon: "error",
                                    button: "oh no!",
                                    timer: 5000,
                                });
                            }
                        } else {
                            setState({ err: true, errMsg: "Please enter a valid mail or Mobile" });
                        }
                    }
                } else {
                    try {
                        transFun(body);
                    } catch (error) {
                        swal({
                            title: " Oops!",
                            text: error,
                            icon: "error",
                            button: "oh no!",
                            timer: 5000,
                        });
                    }
                }
            } else {
                setState({ err: true, errMsg: "Please generate access token" });
            }
        } catch (error) {
            setState({ ...state, err: true, errMsg: "Invalid JSON format" });
        }
    };
    const handleSelect = async (e) => {
        setDisable(false);
        let transactionDetail = {};
        let temp = Campaigns.data[e.index - 1].earningRule.rules[0].infixRule;
        let temp1 = Campaigns.data[e.index - 1].earningRule.rules[0].data[0].TransactionField
        let schemaCode = Campaigns.data[e.index - 1].schemaCode;
        let referalSchema = Campaigns?.data[e.index - 1]?.merchantTransactionSchema?.schemaName
        
        merchantSchema?.filter((element) => {
            if (element.schemaName != "Referral") {
                schemaCode = element.schemaCode;
            }
        });

        for (let i = 0; i < temp.length; i++) {
            if (temp[i]?.field) {
                transactionDetail[temp[i]?.field] = temp[i].op ==="in" ? "" :
                [temp[i]?.type] == "number" ? 0 : [temp[i]?.type] == "array" ? [] : [temp[i]?.type] == "boolean" ? false : [temp[i]?.type] == "enumeration" ? [] : [temp[i]?.type] == "object" ? {} : temp[i]?.value[0];
            }
        }
        let apiData = {
            mobile: "",
        };
      
        apiData = transactionDetail;
        apiData = {
            ...apiData,
            ...Campaigns.data[e.index - 1].earningRule.rules.reduce((acc, rule) => {
                acc[rule.earnigWhoData.Customer] = ""; 
                return acc;
            }, {})
        };

if(temp1) {
    apiData[temp1] = 0;
}

        apiData.schemacode = referalSchema === "Referral" ? "TSC000028" : schemaCode ? schemaCode : "";

        apiData = await JSON.stringify(apiData, null, 2);
        //    requestText=apiData;
        setRequestText(apiData);
    };
    useEffect(() => {}, [requestText]);
    const handleChange = (e) => {
        setRequestText(e.target.value);
    };

    useEffect(() => {
        getMerchantSchema();
    }, []);

    const getMerchantSchema = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const merchantCode = localStorage.getItem("merchantcode");
        let response = await getmerchantschema(token?.access_token, merchantCode);
        setMerchantSchema(response.data);
        setLoader(false);
    };

    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <Header name={"Transactions"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__apiPlaygroundBox">
                        <div className="dashboard__apiPlaygroundHeading">
                            <h2>
                                <span className="badge rounded-pill bg-primary">POST</span> Transaction
                            </h2>
                        </div>
                        <div className="dashboard__apiPlaygroundBottom">
                            <div className="dashboard__apiPlaygroundEndpoint">
                                <p>
                                    <span>API Endpoint</span>
                                    <Link to="#!">https://apisaasnovus.azurewebsites.net/api/Transaction/TransactionManager</Link>
                                </p>
                            </div>
                            <div className="dashboard__apiPlaygroundButton">
                                <button className="btn btn-primary" onClick={handleAccessToken}>
                                    Generate Access Token
                                </button>
                            </div>
                        </div>
                        <div className="dashboard__apiPlaygroundCardMain">
                            {err && (
                                <div className="alert alert-danger" role="alert">
                                    {errMsg}
                                </div>
                            )}
                            {success && (
                                <div className="alert alert-success" role="alert">
                                    {successMsg}
                                </div>
                            )}
                            <div className="dashboard__apiPlaygroundCardHead">
                                <h3>API Simulate</h3>
                            </div>
                            {/* {Campaigns.data.length > 0 ? ( */}
                            <div className="form-group">
                                <ValidatorForm onSubmit={onSubmit}>
                                    <SelectValidator
                                        placeholder="Select campaigns"
                                        className=""
                                        name="choice"
                                        //  value={selectValueGetter(options, valueselected)}
                                        options={option}
                                        onChange={handleSelect}
                                    />
                                </ValidatorForm>
                            </div>
                            {/* ) : null} */}
                            <div className="dashboard__apiPlaygroundCardBox">
                                <div className="dashboard__apiPlaygroundLeft">
                                    <div className="dashboard__requestSection">
                                        <div className="dashboard__header">
                                            <div className="dashboard__left">
                                                <h5>Request</h5>
                                            </div>
                                            <div className="dashboard__right">
                                                <CopyToClipboard text={requestText} onCopy={() => handleCopy("request")}>
                                                    <Link className="btn btn-light" to="">
                                                        {requestCopied ? "Copied" : "Copy"}
                                                    </Link>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        <div className="dashboard__body" id="APICodeBox">
                                            <textarea id="api" value={requestText} onChange={handleChange}></textarea>
                                        </div>
                                        <div className="dashboard__footer">
                                            <button type="button" className="btn btn-light rounded-circle" disabled={disable ? true : false} title="Get Response" onClick={createTransaction}>
                                                <i className="fa-solid fa-play"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard__apiPlaygroundRight">
                                    <div className="dashboard__responseSection">
                                        <div className="dashboard__header">
                                            <div className="dashboard__left">
                                                <h5>Response</h5>
                                            </div>
                                            <div className="dashboard__right">
                                                <CopyToClipboard text={responseText} onCopy={() => handleCopy("response")}>
                                                    <Link className="btn btn-dark" to="">
                                                        {responseCopied ? "Copied" : "Copy"}
                                                    </Link>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        <div className="dashboard__body" id="APICodeBox">
                                            {loading ? (
                                                <div className="mainPreloaderMain">
                                                    <div className="mainPreloader">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <JSONPretty id="json-pretty" data={responseText} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard__apiPlaygroundHeading">
                            <HeadingSection header={"Transactions"} link={"https://apinovusstaging.azurewebsites.net/api/Transaction/TransactionManager"} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    Campaigns: state.Campaigns,
});
const mapDispatchToProps = {
    getCampaigns,
    clearCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionAPI);

// export default TransactionAPI;