import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getmerchantschema, transactionRegistration } from "../pages/campaigns/services";
import { Modal, Button, CloseButton } from "react-bootstrap";
import { connect } from "react-redux";
import { createsample, clearCampaigns } from "../../actions/campaigns";
import { decrypt } from "../../utils/encodingdecoding";
import { getDate } from "../../utils/helper";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "./TextValidator";

const ProgramsCard = ({ heading, description, earningvalue, value, startDate, endDate, text, status, redemptions, path, id, index, data, campaignCode, sample, createsample, tags, firstData, introSampleCamp, clearCampaigns }) => {
    let diff = moment(moment(endDate, "MM-DD-YYYY").toISOString()).diff(moment.utc().format(), "minute");
    const [ApiData, setApidata] = useState({});
    const [loader, setLoader] = useState(false);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [state, setState] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [apidatajson, setApidatajson] = useState({});
    const [modifiedData, setModifiedData] = useState(`{
        "customer" : {
             "mobile" : "9875432145"
        }
        }`);
    const [show1, setShow1] = useState(false);
    const [formdata, setFormdata] = useState({
        schemacode: "",
    });

    const handleClose = () => {
        setShow1(false);
        navigate("/sample-campaigns", { state: location.state.tags });
    };

    const handleShow = async () => {
        data.status = true;
        data.merchantId = [decrypt(localStorage.getItem("merchantcode"))];
        data.merchantTransactionSchema = {
            ...data.merchantTransactionSchema,
            merchants: [decrypt(localStorage.getItem("merchantcode"))],
        };
        data.offerDetail = {
            ...data.offerDetail,
            title: "Point campaign",
        };
        data.tags = [];
        data.internalTags = [];
        // eslint-disable-next-line
        await data?.earningRule?.rules[0]?.infixRule?.map((val, id) => {
            if (typeof val === "object") {
                data.earningRule.rules[0].infixRule[id]["operator"] = data.earningRule.rules[0].infixRule[id]["op"] ? data.earningRule.rules[0].infixRule[id]["op"] : data.earningRule.rules[0].infixRule[id]["operator"];
                delete data.earningRule.rules[0].infixRule[id]["op"];
                // eslint-disable-next-line
                data?.earningRule?.rules[0]?.prefixRule?.rules?.map((value, index) => {
                    if (index * 2 === id) {
                        val.id = value.id;
                        val.data = value.data;
                    }
                });
            }
        });
        var Sponsors = data?.earningRule?.rules[0]?.data[0]?.Sponsors[0];
        var decryptedCode = decrypt(localStorage.getItem("merchantcode"));
        Sponsors.Code = decryptedCode;

        setShow1(false);
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        await createsample(token?.access_token, data);
        setLoader(false);
        navigate("/sample-campaigns", { state: location.state.tags });
    };

    const handleClick = async () => {
        setShow1(true);
    };
    const sanitizedHTML = text ? { __html: text } : null;

    const getMerchantSchema = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const merchantCode = localStorage.getItem("merchantcode");
        let response = await getmerchantschema(token?.access_token, merchantCode);
        setLoader(false);
        return response.data;
    };

    const handleSelect = async (e) => {
        let merchantSchema = await getMerchantSchema();
        let transactionDetail = {};
        let temp = data?.earningRule?.rules[0]?.infixRule;
        let schemaCode = data?.schemaCode;
        merchantSchema?.filter((element) => {
            if (element.schemaName != "Referral") {
                schemaCode = element.schemaCode;
            }
        });
        for (let i = 0; i < temp?.length; i++) {
            if (temp[i]?.field) {
                transactionDetail[temp[i]?.field] = [temp[i]?.type] == "number" ? 0 : [temp[i]?.type] == "array" ? [] : [temp[i]?.type] == "boolean" ? false : [temp[i]?.type] == "enumeration" ? [] : [temp[i]?.type] == "object" ? {} : "";
            }
        }
        let apiData = {
            mobile: "",
        };
        Object.entries(transactionDetail).length && (apiData = transactionDetail);
        apiData = {
            ...apiData,
            [data.earningRule.rules[0].earnigWhoData.Customer]: "",
        };

        apiData.schemacode = schemaCode ? schemaCode : "";
        apiData = await JSON.stringify(apiData, null, 2);
        setApidatajson(apiData);
        setShowModal(true);
        const modified = JSON.parse(apiData);
        setModifiedData(modified);
        setFormdata({ ...formdata, schemacode: schemaCode ? schemaCode : "" });
    };

    const renderJsonToHtml = (data, parentKey = "") => {
        return Object.keys(data).map((key) => {
            const currentKey = parentKey ? `${parentKey}.${key}` : key;
            const value = data[key];
            const childKey = parentKey ? `${parentKey}_${key}` : key;
            return (
                <div key={childKey} className="mb-3">
                    <label htmlFor={childKey} className="form-label">
                        {key.toUpperCase()}
                    </label>
                    {typeof value === "object" && value !== null ? renderJsonToHtml(value, currentKey) : <TextValidator type="text" id={childKey} name={currentKey} className="form-control" value={value} readOnly={currentKey === "schemacode"} validators={["required"]} errorMessages={["Please Enter Value"]} onChange={handleChange} />}
                </div>
            );
        });
    };

    const updateNestedState = (obj, keys, value) => {
        if (keys.length === 1) {
            return { ...obj, [keys[0]]: value };
        } else {
            const [currentKey, ...remainingKeys] = keys;
            return {
                ...obj,
                [currentKey]: updateNestedState(obj[currentKey] || {}, remainingKeys, value),
            };
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        const keys = name.split(".");
        setModifiedData((prevData) => updateNestedState(prevData, keys, value));
        const updatedData = updateNestedState(modifiedData, keys, value);
        const data = await JSON.stringify(updatedData, null, 2);
        setApidata(data);
        setIsDataChanged(true);
        setFormdata({ ...formdata, [keys[1]]: keys[1] ? value : value });
    };

    const onSubmit = async () => {
        setLoader(true);
        let body = ApiData;
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const transactionReg = await transactionRegistration(token?.access_token, JSON.stringify(formdata));
        setLoader(false);
        setState(transactionReg);
        setIsDataChanged(false);
    };

    useEffect(() => {
        handleCloseModal();
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
        setState({});
    };
    const endingdate = getDate(endDate, "DD-MMM-YYYY");
    const expire_date = `Expired On ${endingdate}`;

    return (
        <div className={introSampleCamp ? "dashboard__programsCard dashboard__programsCardSC" : "dashboard__programsCard"}>
            <Modal show={show1} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "20px" }}>Create New Campaign</Modal.Title>
                    <CloseButton onClick={handleClose} variant="white" />
                </Modal.Header>
                <Modal.Body>Do you want to Create a New Campaign</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button className="btn btn-primary" onClick={handleShow}>
                        Create Campaign
                    </Button>
                </Modal.Footer>
            </Modal>
            {showModal && (
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header>
                        <Modal.Title style={{ fontSize: "20px" }}>Transaction Details</Modal.Title>
                        <CloseButton onClick={handleCloseModal} variant="white" />
                    </Modal.Header>
                    <Modal.Body>
                        <ValidatorForm onSubmit={onSubmit}>
                            {renderJsonToHtml(modifiedData)}
                            {state && Object.keys(state).length > 0 && (
                                <div>
                                    <b style={{ justifyContent: "center", display: "flex" }}>Response</b>
                                    {state.message ? (
                                        <>
                                            <p style={{ color: "green" }}>{state.message}</p>
                                            {state.data && Array.isArray(state.data) && state.data.length > 0 && (
                                                <>
                                                    <p style={{ color: "green" }}>Earned : {state.data[0].earnedValue + " " + state.data[0].qualifiedCampaign.type}</p>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <pre style={{ color: "green" }}>{JSON.stringify(state, null, 2)}</pre>
                                    )}
                                </div>
                            )}
                            <div className="dashboard__footer" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <button
                                    type="submit"
                                    // onClick={createTransaction}
                                    style={{
                                        alignItems: "center",
                                        display: "flex",
                                        height: "2rem",
                                        width: "2rem",
                                        padding: "0",
                                        justifyContent: "center",
                                        backgroundColor: "#babbbc",
                                    }}
                                    className="btn btn-light rounded-circle"
                                    title="Get Response"
                                >
                                    <i className="fa-solid fa-play"></i>
                                </button>
                            </div>
                        </ValidatorForm>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__header">
                <div className="dashboard__left">
                    <h5 title={heading}>{heading}</h5>
                    <p style={{ fontSize: "13px" }}>
                        get
                        {earningvalue === "Voucher" ? " Voucher " : earningvalue === "Percentage-Point" ? ` ${value}% of purchase amount ` : earningvalue === "Fix-Point" ? ` ${value} points` : ""}
                    </p>
                    {/* {diff && diff > 0 ? <span className="activeCampaign"></span> : <span className="expiredCampaign"></span>} */}

                    {/* <p>{description}</p> */}
                </div>
                <div className="dashboard__right">
                    <ul>
                        {/* {sample && (
                            <li>
                                <Link className="dashboard__create" title="Create Campaign" to="" onClick={handleClick} state={{ code_id: id, index: index, data: data, tags: tags }} >
                                    <i className="fa-solid fa-plus"></i>
                                </Link>
                            </li>
                        )} */}

                        {!sample && <li>{status && diff && diff > 0 ? <span className="activeCampaign" title={expire_date}></span> : <span className="expiredCampaign" title={expire_date}></span>}</li>}
                        <li>
                            <Link id={firstData ? "edit_cmp" : " "} className="dashboard__edit" title="View Campaign" to={path} state={{ code_id: id, index: index, data: data, type: sample ? "sample" : null, prevPath: location.pathname, tags: tags, sampleFor: "view" }}>
                                {sample ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-pen"></i>}
                            </Link> 
                        </li>
                        {/* <li>
                            <span to="/" className="dashboard__options" onClick={programsCardAddOptions}>
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                            </span>
                            <div className={show ? "dashboard__optionsBox show" : "dashboard__optionsBox"}>
                                <ul>
                                    <li>
                                        <span className="expire">
                                            <i className="fa-solid fa-circle-xmark"></i> Expire Campaign
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
                    </ul>
                    {sample && (
                        <div className="dashboard__ccampaign">
                            <Link id={firstData ? "create_cmp" : " "} className="dashboard__edit" title="Edit Campaign" to={path} state={{ code_id: id, index: index, data: data, type: sample ? "sample" : null, prevPath: location.pathname, tags: tags, sampleFor: "edit" }}>
                                <i className="fa-solid fa-plus"></i>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
            <div className="dashboard__body">
                <p style={{ fontSize: "13px" }} dangerouslySetInnerHTML={sanitizedHTML} title={text}></p>
            </div>
            <hr></hr>
            {!sample && (
                <div className="dashboard__link">
                    <div className="row">
                        <div className="col-md-9">
                            <Link to="/uploading-existing-datas" state={{ from: "transaction" }}>
                                Upload Transaction
                            </Link>
                        </div>
                        <div className="col-md-3">
                            <Link to="/report" state={{ campaignId: id }}>
                                Report
                            </Link>
                        </div>
                        {/*
                        <div className="col-md-4">
                            <Link to="" className="edit_btn" csvalue="View Details" onClick={handleSelect}>
                                Earn Reward
                                <div className="show_div">Transaction</div>
                            </Link>
                        </div> */}
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return { Campaigns: state.Campaigns };
};

const mapDispatchToProps = {
    createsample,
    clearCampaigns,
    // getSampleCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramsCard);
