import { Link } from "react-router-dom";
export const COLUMNS = [

    {
        Header: "Name",
        Footer: "Name",
        accessor: "name",
    },

    {
        Header: "Email",
        Footer: "Email",
        accessor: "email",

    },

    {
        Header: "Mobile",
        Footer: "Mobile",
        accessor: "mobile",

    },
    {
        Header: "Membership Type ",
        Footer: "membershipType",
        accessor: "membershipType",
    },
    {
        Header: "Amount",
        Footer: "Amount",
        accessor: "amount",
    },

    {
        Header: "Purchased Date",
        Footer: "Purchased Date",
        accessor: "date",

    },

    {
        Header: "Payment Status",
        Footer: "Payment Status",
        accessor: "status",

    },
   
   

];
