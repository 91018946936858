import React, { useState, useEffect } from "react";
import Header from "../../layout/Header";
import { Tabs, Tab } from "react-bootstrap";
import { backIcon2, profileImage, transaction } from "../../../Image/Index";
import { getDate } from "../../../utils/helper";
import { transactionRedeem, customerView, RedeemVoucher, getVoucherTemplate, getVoucherTemplateStatusByCode } from "../campaigns/services";
import TransactionViewModal from "../transactionsList/TransactionViewModal";
import Page from "../Pagination";
import RedeemRewardsModal from "./RedeemRewardsModal";
import swal from "sweetalert";
import { Link, useNavigate, useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import RedeemableModal from "./RedeemableModal";

const CustomerView = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [response, setResponse] = useState({});
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        name: "",
        mobile: "",
        referralcode:"",
        tier:"",
        customerid:"",
        email: "",
        customer_Code: "",
        merchant_Id: "",
        availableValue: "",
        totalAvailableValue: 0,
        totalEarnPoints: "0",
        totalExpiredPoints: "0",
        totalRedeemPoints: "0",
        redeemablePoint: 0,
        walletSummary: [],
        transaction_s: [],
        vouchersList: [],
        inputRedeemPoints: "",
        error: "",
        successMsg: "",
        close: false,
        activityLogs: [],
        limit: 5,
        total: 0,
        disable: true,
    });
    const [showLogin, setShowLogin] = useState(false);
    const [redeemModalShow, setRedeemModalShow] = useState(false);
    const [redeemableModalShow, setRedeemableModalShow] = useState(false);
    const [transactionId, setTransactionId] = useState("");
    const [redeem, setRedeem] = useState({
        customer: {
            customercode: "",
        },
        redeemDetail: {
            walletType: "point",
            values: "",
            transactionType: "redeem",
            transactionDate: "",
        },
    });
    const [voucher, setVoucher] = useState({
        qualifiedVoucherList: [],
        flag: true,
        voucherTemplate: [],
    });
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPagev, setCurrentPagev] = useState(0);

    const { name, mobile,referralcode, customerid, tier,email, customer_Code, availableValue, totalAvailableValue, totalEarnPoints, totalExpiredPoints, totalRedeemPoints, transaction_s, error, inputRedeemPoints, successMsg, activityLogs, limit, total, disable, redeemablePoint, walletSummary } = state;
    const { customer, redeemDetail } = redeem;
    const { qualifiedVoucherList, flag, voucherTemplate } = voucher;

    useEffect(() => {
        setLoader(true);
        fetch();
    }, []);
    useEffect(() => {
        if (flag) {
            fetchVoucher(); // eslint-disable-next-line
        }
    }, [flag]);

    useEffect(() => {
        const voucherType = [];
        response?.transactions?.length && response?.transactions?.map((value) => value?.qualifiedCampaign?.length > 0 && value?.qualifiedCampaign[0]?.type === "voucher" && voucherType.push(value));
        setState({
            ...state,
            name: response?.customer?.name ? response?.customer?.name : "",
            mobile: response?.customer?.mobile ? response.customer.mobile : "",
            referralcode:response?.customer?.referralcode ? response.customer.referralcode : "",
            tier:response?.customer?.tier?.name ? response.customer.tier?.name : "",
            customerid:response?.customer?.customerid ? response.customer.customerid : "",
            email: response?.customer?.email ? response.customer.email : "",
            customer_Code: response?.customer?.customercode,
            availableValue: response?.walletsDetail?.availableValue != null ? response?.walletsDetail?.availableValue : 0,
            totalEarnPoints: response?.walletsDetail?.earnedValue != null ? response?.walletsDetail?.earnedValue : 0,
            totalRedeemPoints: response?.walletsDetail?.redeemValue != null ? response?.walletsDetail?.redeemValue : 0,
            totalExpiredPoints: response?.walletsDetail?.expiredValue != null ? response?.walletsDetail?.expiredValue : 0,
            totalAvailableValue: response?.walletsDetail?.TotalavailableValue != null ? response?.walletsDetail?.TotalavailableValue : 0,
            redeemablePoint: response?.walletsDetail?.earnedValueRedemptionAfterDate != null ? response?.walletsDetail?.earnedValueRedemptionAfterDate : 0,
            walletSummary: response?.WalletSummary?.filter((wallet) => getDate(wallet?.redeemableAfterDate, "YYYY-MM-DD") > getDate(new Date(), "YYYY-MM-DD") && wallet),
            transaction_s: response?.transactions ? response?.transactions : [],
            inputRedeemPoints: response?.walletsDetail?.TotalavailableValue != null ? response?.walletsDetail?.TotalavailableValue : 0,
            activityLogs: response?.activityLogs ? response?.activityLogs.reverse() : [],
            total: response?.transactions ? response?.transactions.length : 0,
        });
        // eslint-disable-next-line
    }, [response]);
    const [matchedVoucherStatus, setMatchedVoucherStatus] = useState([]);
    useEffect(() => {
        let temp = [];
        response?.transactions?.length &&
            response?.transactions?.map((e) => {
                if (e.qualifiedCampaign && e.qualifiedCampaign.length) {
                    e.qualifiedCampaign.filter((item) => {
                        if (item.type === "voucher") {
                            return temp.push({
                                voucherCode: item.voucherCode,
                                value: item.value,
                                templateCode: item.voucherTemplateCode,
                                transactionType: e.transactionDetail.transactionType,
                                customerCode: e.customer[0]?.customercode,
                                transactionDate: new Date().toISOString(),
                            });
                        }
                        return null;
                    });
                }
                return null;
            });

        setVoucher({ ...voucher, qualifiedVoucherList: temp });
        // eslint-disable-next-line
    }, [response, voucherTemplate]);
    const handleVoucher = async () => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const body = {
            customercode: customer_Code,
        };
        let voucherStatusResponse = await getVoucherTemplateStatusByCode(token?.access_token, body);
        matchVoucherCodes(voucherStatusResponse);
    };

    useEffect(() => {
        customer_Code && handleVoucher();
    }, [customer_Code]);

    const matchVoucherCodes = (voucherStatusResponse) => {
        const matchedVouchers = [];
        if (Array.isArray(voucherStatusResponse) && voucherStatusResponse?.length) {
            voucherStatusResponse.forEach((voucherStatus) => {
                voucher.qualifiedVoucherList.forEach((voucher) => {
                    if (voucherStatus.voucherCode === voucher.voucherCode) {
                        matchedVouchers.push({
                            voucherTemplateCode: voucherStatus.voucherTemplateCode,
                            voucherCode: voucherStatus.voucherCode,
                            status: voucherStatus.voucherUsed,
                        });
                    }
                });
            });
        }
        storeVoucherStatus(matchedVouchers);
    };
    const storeVoucherStatus = (matchedVouchers) => {
        setMatchedVoucherStatus(matchedVouchers);
    };

    useEffect(() => {
        let tempCustomer_code = customer_Code;
        let tempInputRedeemPoints = parseInt(inputRedeemPoints);
        setRedeem({
            ...redeem,
            customer: { ...customer, customercode: tempCustomer_code },
            redeemDetail: { ...redeemDetail, values: tempInputRedeemPoints, transactionDate: getDate(new Date(), "YYYY-MM-DD") },
        });
    }, [customer_Code, inputRedeemPoints, totalAvailableValue]);

    useEffect(() => {
        if (totalAvailableValue > 0) {
            setState({ ...state, disable: false });
        }
    }, [totalAvailableValue]);
    const path = window.location.pathname;
    const customerPath = path.split("/")[2];

    const fetch = async () => {
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        tempToken = tempToken?.access_token;
        await customerView(tempToken, customerPath)
            .then((res) => {
                setResponse(res);
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            });
    };
    const fetchVoucher = async () => {
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        tempToken = tempToken?.access_token;
        setLoader(true);
        await getVoucherTemplate(tempToken)
            .then((res) => {
                setVoucher({ ...voucher, voucherTemplate: res.data });
            })
            .catch(() => {
                setLoader(false);
            });
    };

    const getVoucherDate = (value) => {
        var expdate = "";
        voucherTemplate?.length &&
            voucherTemplate?.map((val) => {
                if (val.code === value) expdate = val.voucherValidityDate.endDate;
            });
        return expdate;
    };
    const getValue = (value) => {
        var earningValue = "";
        voucherTemplate?.length &&
            voucherTemplate?.map((val) => {
                if (val.code === value) earningValue = val.voucherValue;
            });
        return earningValue;
      
    };
    const handleRedeemtion = async (value, camp) => {
        setLoader(true);
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        tempToken = tempToken?.access_token;
        let body = {};
        body.customer = {
            customercode: value.customerCode,
        };

        body.redeemDetail = {
            walletType: "Voucher",
            voucherCode: value.voucherCode,
            voucherTemplateCode: value.templateCode,
            transactionType: "redeem",
            transactionDate: value.transactionDate,
        };
        await RedeemVoucher(tempToken, body).then((res) => {
            if (res.status === 400) {
                swal({
                    position: "center",
                    icon: "error",
                    title: res.message,
                    showConfirmButton: false,
                    timer: 5000,
                });
            } else {
                swal({
                    position: "center",
                    icon: "success",
                    title: "Voucher Redeemed Successfully",
                    showConfirmButton: false,
                    timer: 5000,
                });
            }
            setLoader(false);
            handleVoucher();
        });
    };

    const handleChange = (e, minimumValue) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
        if (e.target.value < minimumValue || totalAvailableValue < e.target.value) {
            if (!e.target.value || e.target.value <= 0) {
                setState({ ...state, error: null, disable: true });
            }
            if ((e.target.value || e.target.value > 0) && e.target.value < minimumValue) setState({ ...state, error: `Redeem value must be greater than ${minimumValue - 1}!`, disable: true });
            if (totalAvailableValue < e.target.value) setState({ ...state, error: `Redeem value must be less than or equal to ${totalAvailableValue} !`, disable: true });
        } else {
            setState({ ...state, [e.target.name]: e.target.value, error: null, disable: false });
        }
    };
    const handleRedeemApi = async () => {
        if (!error) {
            let tempToken = JSON.parse(localStorage.getItem("token_gen"));
            tempToken = tempToken?.access_token;
            let body = JSON.stringify(redeem);
            setLoader(true);
            let response = await transactionRedeem(tempToken, body);
            await fetch();
            setVoucher({ ...voucher, flag: false });
            setLoader(false);
            if (response?.message === "Request processed successfully.") {
                setState({ ...state, successMsg: "Redeem points successfully" });
            } else {
                setState({ ...state, error: "Your points can't redeem now. Please try later" });
            }
        }
    };

    const handleShowModal = (id) => {
        setShowLogin(true);
        setTransactionId(id);
    };

    const displayedArray = activityLogs?.length && activityLogs.slice(0, limit);
    const handleLoadMore = () => {
        setState((prevState) => ({ ...prevState, limit: prevState.limit + 5 }));
    };

    const handleOpenRewardsModal = () => {
        setRedeemModalShow(true);
        setState({ ...state, totalAvailableValue: totalAvailableValue });
    };

    const handleCloseRewardsModal = () => {
        setState({ ...state, successMsg: "", error: "", disable: false });
        setRedeemModalShow(false);
    };

    const openRedeemableModal = () => {
        setRedeemableModalShow(true);
    };

    const closeRedeemableModal = () => {
        setRedeemableModalShow(false);
    };

    const pageSize = 10;
    const pageCount = Math.ceil(total / pageSize);
    const pageCountv = Math.ceil(qualifiedVoucherList.length / pageSize);
    const [matchData, setMatchdata] = useState([]);
    useEffect(() => {
        const matchedVouchers = [];

        voucherTemplate?.forEach((voucherCode) => {
            voucher?.qualifiedVoucherList.forEach((voucher) => {
                if (voucherCode.code === voucher.templateCode) {
                    matchedVouchers.push({
                        code: voucherCode.code,
                        value: voucherCode.earningValue,
                    });
                }
            });
        });

        setMatchdata(matchedVouchers);
    }, [voucherTemplate, voucher.qualifiedVoucherList]);

    const [matchedCode, setMatchedCode] = useState([]);

    useEffect(() => {
        if (transaction_s && Array.isArray(transaction_s) && matchData && Array.isArray(matchData)) {
            const matchedVouchers = [];
            transaction_s.forEach((transaction) => {
                if (transaction.qualifiedCampaign && Array.isArray(transaction.qualifiedCampaign)) {
                    const qualifiedCampaign = transaction.qualifiedCampaign;
                    const newStateArray = [];
                    matchData.forEach((voucherCode) => {
                        qualifiedCampaign.forEach((voucher) => {
                            if (voucherCode.code === voucher.voucherTemplateCode) {
                                newStateArray.push({
                                    value: voucherCode.value,
                                });
                            }
                        });
                    });

                    setMatchedCode(newStateArray);
                }
            });
        }
    }, [matchData, transaction_s]);

    const renderVoucherValue = (transaction, matchedCode) => {
        if (transaction.qualifiedCampaign && transaction.qualifiedCampaign.some((voucher) => voucher.type === "voucher")) {
            const voucherValues = transaction.qualifiedCampaign.filter((voucher) => voucher.type === "voucher").map((voucher) => matchedCode.find((matchedVoucher) => matchedVoucher.id === voucher.id)?.value || "-");

            return voucherValues.join(", ");
        } else {
            return transaction.transactionDetail.amount || "-";
        }
    };

    const generatePDF = () => {
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "normal");
        const columns = ["Trn ID(last 4 digits)", "Rewards", "Trn Type", "Date"];
        let y = 20;
        columns.forEach((column, index) => {
            pdf.text(column, 10 + index * 40, y);
        });
        y += 10;

        transaction_s.forEach((transaction) => {
            const last4Digits = transaction?.transactionId.slice(-4); // Extract last 4 digits
            const rowData = [last4Digits, transaction.transactionDetail.transactionType === "redeem" ? transaction.transactionDetail.values + " " + transaction.transactionDetail.walletType : transaction?.qualifiedCampaign?.reduce((sum, q) => sum + (q.value > 0 ? q.value : 0), 0) + " " + transaction?.qualifiedCampaign?.[0]?.type?.toLowerCase(), transaction.transactionDetail.transactionType, getDate(transaction.transactionProcessedDate, "DD-MM-YYYY")];

            rowData.forEach((cell, index) => {
                const xOffset = index === 0 ? 10 : 10 + index * 40;
                pdf.text(String(cell), xOffset, y);
            });
            y += 10;
        });

        pdf.save("report.pdf");
    };

    const generateCSV = () => {
        const data = [["Transaction ID", "Rewards", "Transaction Type", "Date"]];

        transaction_s.forEach((transaction) => {
            const rowData = [
                transaction?.transactionId, transaction.transactionDetail.transactionType === "redeem" ? transaction.transactionDetail.values + " " + transaction.transactionDetail.walletType : transaction?.qualifiedCampaign?.reduce((sum, q) => sum + (q.value > 0 ? q.value : 0), 0) + " " + transaction?.qualifiedCampaign?.[0]?.type?.toLowerCase(), transaction.transactionDetail.transactionType, getDate(transaction.transactionProcessedDate, "DD-MM-YYYY")
            ];

            data.push(rowData);
        });

        const csvContent = "data:text/csv;charset=utf-8," + data.map((row) => row.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "report.csv");
        document.body.appendChild(link);
        link.click();
    };

    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <Header name={"Customer View"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__customerViewMain">
                        <div className="dashboard__heading">
                            <div className="dashboard__headingLeft">
                                <div className="dashboard__headingBackLink">
                                    <Link to="" state={{ from: location?.state?.from === "WalletReport" ? "WalletReport" : "" }} onClick={() => navigate(-1)}>
                                        <img src={backIcon2} alt="" />
                                        <div className="dashboard__headingContent">
                                            <h3>{location?.state?.from === "WalletReport" ? "Back" : "Back to List"}</h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard__customerViewCard">
                            <div className="dashboard__customerViewProfile">
                                <div className="dashboard__customerViewImage">
                                    <img src={profileImage} alt="" />
                                </div>
                                <div className="dashboard__customerViewDetails">
                                    <div className="dashboard__customerViewTop">
                                        <h2>Personal Detail</h2>
                                    </div>
                                    <div className="dashboard__customerViewBottom">
                                        <div className="dashboard__customerViewLeft">
                                            <ul>
                                                <li>Name: {name}</li>
                                                <li>Contact Number: {mobile}</li>
                                                <li>Referral Code: {referralcode}</li>
                                                <li>Tier: {tier}</li>
                                            </ul>
                                        </div>
                                        <div className="dashboard__customerViewRight">
                                            <ul>
                                                <li>Customer Code: {customer_Code}</li>
                                                <li>Email Id: {email}</li>
                                                <li>Customer Id: {customerid}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard__customerViewCard">
                            <div className="dashboard__customerViewPointsTabs">
                                <Tabs id="customerViewPointsTabs">
                                    <Tab eventKey="Timeline" title="Timeline">
                                        {Array.isArray(displayedArray) ? (
                                            <div className="dashboard__customerViewTimeline">
                                                {displayedArray?.length &&
                                                    displayedArray?.map((item, i) => (
                                                        <div className="dashboard__customerViewBox" key={i}>
                                                            <span className="timelineIcon active"></span>
                                                            <p>{getDate(item.activityDateTime, "YYYY-MM-DD hh:mm:ss")}</p>
                                                            <h4>Action :- {item.action}</h4>
                                                            {item.transactionType && <h4>Transaction Type:- {item.transactionType}</h4>}
                                                            {item.transactionType == "purchase" && (item.value || !item.voucherCode) ? <h4>Earned:-{item?.value} Points</h4> : item.voucherCode && <h4>Earned:- {item.valueType} </h4>}
                                                            {item.transactionType == "redeem" && <h4>Redeem:- {item.value} Points</h4>}
                                                          
                                                        </div>
                                                    ))}
                                                {limit < activityLogs.length && (
                                                    <button className="btn btn-primary" onClick={handleLoadMore}>
                                                        Load More
                                                    </button>
                                                )}
                                            </div>
                                        ) : (
                                            <h6 className="text-center">No Record Found</h6>
                                        )}
                                    </Tab>
                                    <Tab eventKey="Points" title="Points">
                                        <div className="dashboard__customerViewPoints">
                                            <div className="row">
                                                <div className="col-lg-10">
                                                    <div className="row gx-2">
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="dashboard__customerViewBox">
                                                                <h4>Available Reward Points</h4>
                                                                <h2>{availableValue}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="dashboard__customerViewBox">
                                                                <h4>Available Reward For Redeem</h4>
                                                                <h2>{totalAvailableValue}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="dashboard__customerViewBox">
                                                                <h4>Reward Redemption After Date</h4>
                                                                {redeemablePoint > 0 ? 
                                                                <h2>
                                                                    <span className="totalReward" onClick={openRedeemableModal}>
                                                                        {redeemablePoint}
                                                                        <span className="line">|</span>
                                                                        <i class="fa-solid fa-eye size2"></i>
                                                                    </span>
                                                                </h2> :
                                                                   <h2>{redeemablePoint}</h2>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="dashboard__customerViewBox">
                                                                <h4>Total Earned Points</h4>
                                                                <h2>{totalEarnPoints}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="dashboard__customerViewBox">
                                                                <h4>Total Expired Points</h4>
                                                                <h2>{totalExpiredPoints} </h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="dashboard__customerViewBox">
                                                                <h4>Total Redeemed Points</h4>
                                                                <h2>{totalRedeemPoints}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2">
                                                    <button className="btn btn-primary w-100" disabled={totalAvailableValue > 0 ? false : true} onClick={handleOpenRewardsModal}>
                                                        Redeem Rewards
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Vouchers" title="Vouchers">
                                        {qualifiedVoucherList?.length > 0 ? (
                                            <div className="table-responsive">
                                                <table className="table table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Voucher Code</th>
                                                            <th>Value</th>
                                                            <th>Expire Date</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {qualifiedVoucherList &&
                                                            qualifiedVoucherList.length > 0 &&
                                                            qualifiedVoucherList.slice(currentPagev * pageSize, (currentPagev + 1) * pageSize).map((transaction, i) => (
                                                                <tr key={i}>
                                                                    <td>{transaction?.voucherCode}</td>

                                                                    <td>${getValue(transaction?.templateCode)}</td>
                                                                    <td>{getDate(getVoucherDate(transaction?.templateCode), "DD-MM-YYYY")}</td>
                                                                    <td>
                                                                        {matchedVoucherStatus.map((item, index) => {
                                                                            if (item.voucherCode === transaction.voucherCode) {
                                                                                return (
                                                                                    <button key={index} style={{ textTransform: "capitalize" }} className={item.status ? "badge bg-warning" : "badge bg-success"} onClick={() => handleRedeemtion(transaction)} disabled={item.status}>
                                                                                        {item.status ? "redeemed" : "redeem"}
                                                                                    </button>
                                                                                );
                                                                            }
                                                                            return null;
                                                                        })}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                <div className="dashboard__paginationBox">
                                                    <Page currentPage={currentPagev} setCurrentPage={setCurrentPagev} pageCount={pageCountv} />
                                                </div>
                                            </div>
                                        ) : (
                                            <h6 className="text-center"> No Record Found</h6>
                                        )}
                                    </Tab>
                                    <Tab eventKey="Transactions" title="Transactions">
                                        <div className="dropdown" style={{ justifyContent: "end", display: "flex", marginBottom: "10px" }}>
                                            <button className="btn btn-danger dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Download
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <button className="dropdown-item" onClick={generatePDF}>
                                                    Download PDF
                                                </button>
                                                <button className="dropdown-item" onClick={generateCSV}>
                                                    Download CSV
                                                </button>
                                            </div>
                                        </div>
                                        {transaction_s.length > 0 ? (
                                            <div className="table-responsive" id="transaction_Report">
                                                <table className="table table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Transaction ID</th>
                                                            {/* <th>Transaction Amount</th> */}
                                                            <th>Rewards</th>
                                                            <th>Transaction Type</th>
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transaction_s &&
                                                            transaction_s
                                                                .sort((a, b) => {
                                                                    const aDiff = Math.abs(new Date(a.transactionProcessedDate) - new Date());
                                                                    const bDiff = Math.abs(new Date(b.transactionProcessedDate) - new Date());
                                                                    return aDiff - bDiff;
                                                                })
                                                                .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                                                                .map((transaction, i) => (
                                                                    <tr key={i}>
                                                                        <td onClick={() => handleShowModal(transaction?.transactionId)} style={{ cursor: "pointer", color: "#0d6efd" }}>
                                                                            {transaction?.transactionId}
                                                                        </td>

                                                                        {/* <td style={{ justifyContent: "center", display: "flex" }}>{renderVoucherValue(transaction, matchedCode)}</td> */}
                                                                        <td>
                                                                            {transaction?.transactionDetail?.transactionType === "redeem"
                                                                                ? (transaction.transactionDetail.values ? transaction.transactionDetail.values : "-") + " " + (transaction.transactionDetail.walletType ? transaction.transactionDetail.walletType : " ")
                                                                                : transaction?.qualifiedCampaign?.length
                                                                                    ? transaction?.qualifiedCampaign[0].type === "voucher"
                                                                                        ? voucher?.voucherTemplate?.length &&
                                                                                        voucher?.voucherTemplate?.map((data) => {
                                                                                            if (transaction?.qualifiedCampaign[0].voucherTemplateCode == data.code) {
                                                                                                return "Earn voucher worth " + " " + data.voucherValue;
                                                                                            }
                                                                                        })
                                                                                        : transaction?.qualifiedCampaign?.reduce((sum, q) => sum + (q.value > 0 ? q.value : 0), 0) + " " + transaction?.qualifiedCampaign?.[0]?.type?.toLowerCase()
                                                                                    : transaction?.transactionDetail?.transactionType === "refund" ? transaction?.transactionDetail?.refundAmount : "-" }
                                                                        </td>
                                                                        <td>
                                                                            <span style={{ textTransform: "capitalize" }} >
                                                                                {transaction?.transactionDetail?.bitType?.length > 0  ? transaction?.transactionDetail?.bitType : transaction?.transactionDetail?.transactionType}
                                                                            </span>
                                                                        </td>
                                                                        <td>{getDate(transaction.transactionProcessedDate, "DD-MMM-YYYY")}</td>
                                                                    </tr>
                                                                ))}
                                                    </tbody>
                                                </table>
                                                <div className="dashboard__paginationBox">
                                                    <Page currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} value={5} />
                                                </div>
                                            </div>
                                        ) : (
                                            <h6 className="text-center"> No Record Found</h6>
                                        )}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showLogin && <TransactionViewModal show={showLogin} close={() => setShowLogin(false)} transactionId={showLogin && transactionId} />}

            {redeemModalShow && <RedeemRewardsModal show={redeemModalShow} close={handleCloseRewardsModal} handleRedeemApi={handleRedeemApi} totalAvailableValue={totalAvailableValue} handleChange={handleChange} error={error} successMsg={successMsg} disable={disable} />}
            {redeemableModalShow && <RedeemableModal show={redeemableModalShow} close={closeRedeemableModal} walletSummary={walletSummary} />}
        </>
    );
};

export default CustomerView;
