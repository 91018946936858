import { Modal, Button, CloseButton } from "react-bootstrap";
import parse from "html-react-parser";

const EmailTemplateModal = (props) => {

    return (
        <>
            <Modal show={props.show} onHide={props.close} size="lg" centered>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "20px" }}>{props.header} Description</Modal.Title>
                    <CloseButton onClick={props.close} variant="white" />
                </Modal.Header>
                <Modal.Body className="dashboard__transactionsModal" style={{ position: "relative", minHeight: "85px" }}>

                    <div className="dashboard__transactionsModalProfile">
                        <div className="dashboard__transactionsModalDetails">
                            <div className="dashboard__transactionsModalBottom">
                                <div className="cmsBody">
                                    {parse(props.body)}
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EmailTemplateModal;
