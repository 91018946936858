import React, { useState, useEffect, memo } from "react";
import Header from "../layout/Header";
import Paginated from "./customersList/Paginated";
import { Link } from "react-router-dom";
import { COLUMNS } from "./customersList/column";
// eslint-disable-next-line
import { importIcon } from "../../Image/Index";
import { connect } from "react-redux";
import { getCustomer, clearCustomer } from "../../actions/customer";
import { decrypt } from "../../utils/encodingdecoding";
import { COLUMNS1 } from "./customersList/column1";

const CustomersList = memo(({ getCustomer, Customer, clearCustomer }) => {
    const [data, setData] = useState([]);
    const [response, setResponse] = useState([]);
    const [loader, setLoader] = useState(true);
    let id = decrypt(localStorage.getItem("Id"))
    useEffect(() => {
        let tempPageIndex = JSON.parse(localStorage.getItem("CusPageIndex"));
        if (tempPageIndex === 1) {
            fetch();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setResponse(Customer?.data)
    }, [Customer])

    useEffect(() => {

        const temp = response?.data?.data;

        if (temp) {

            temp?.map((val) => {
                return (
                  
                    val.name = val.customerData.name,
                    val.customerId = val.customerData.customerid,
                    val.email = val.customerData.email,
                    val.mobile = val.customerData.mobile,
                    val.earnedValue = val.walletData.wallet.earnedValue,
                    val.availableValue = val.walletData.wallet.availableValue,
                    val.redeemValue = val.walletData.wallet.redeemValue,
                    val.action = val.customerData.customercode
                    )
            })

            setData(temp);
        }
    }, [response]);

    const fetch = async () => {
        setLoader(true);
        await clearCustomer();
        const token = JSON.parse(localStorage.getItem("token_gen"));
        let id = localStorage.getItem("Id");
        await getCustomer(token?.access_token, id, 1, 50," ");
        setTimeout(() => {
            setLoader(false);
        }, 1000);
    };
    // eslint-disable-next-line

    return (
        <>
            <Header name={"Customer"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__transactionsCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4>Customer Data</h4>
                            </div>
                            <div className="dashboard__right">
                                <Link to="/uploading-existing-datas" id="upload_customer" state={{ from: "customer" }} className="btn btn-default">
                                    <img src={importIcon} alt="" /> Upload Customer
                                </Link>
                            </div>
                        </div>
                        <div className="dashboard__body">
                            <p>No existing customer data.</p>
                            <p>You can add the data by uploading a CSV file containing the details.</p>
                        </div>
                    </div>
                    <div className="dashboard__customersCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4 id="customer_list">Customer List</h4>
                            </div>
                        </div>
                        {id==="MER000252" ?
                          <div className="dashboard__body">
                          <Paginated data={data} columns={COLUMNS1} loader={loader} setLoader={setLoader} />
                      </div> : 
                        <div className="dashboard__body">
                        <Paginated data={data} columns={COLUMNS} loader={loader} setLoader={setLoader} />
                    </div>
                        } 
                    </div>
                </div>
            </div>
        </>
    );
});

const mapStateToProps = (state) => ({
    Customer: state.Customer,
});
const mapDispatchToProps = {
    getCustomer,
    clearCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersList);
