import React, { memo, useEffect, useMemo, useState } from "react";
import Header from "../../layout/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { dashboardIconTwo, backIcon2, dashboardIconThree, dashboardIconOne } from "../../../Image/Index";
import LineChartAnalyticalReport2 from "../../charts/LineChartAnalyticalReport2";
import { decrypt } from "../../../utils/encodingdecoding";
import { connect } from "react-redux";
import { getBusinessGoal, clearBusinessGoal } from "../../../actions/businessGoal";
import { clearCampaigns } from "../../../actions/campaigns";
import { Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import BarChartAnalyticalReportGrowth from "../../charts/BarChartAnalyticalReportGrowth";
import { getUserJourneyReport } from "../../../actions/dashboard";
import IDBIMccData from "./IDBIMccData.json";
import { growthprospect, totalspend } from "../campaigns/services";
import TransactionCount from "./TransactionCount.json";
import CustomerCount from "./CustomerCount.json";
import BarChartAnalyticalReport1 from "../../charts/BarChartAnalyticalReport1";

const GrowthProspect = memo(({ getBusinessGoal, clearBusinessGoal, BusinessGoal, clearCampaigns }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState("-1");
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        tags: [],
        disable: true,
        data: [],
        goals: [],
    });
    const [formDataGraph, setFormdataGraph] = useState([]);
    const [Report1, setReport1] = useState([]);
    const [Report2, setReport2] = useState([]);
    const [title, settTitle] = useState("Brand Awareness");
    const [amount, setAmount] = useState([]);
    const [response, setResponse] = useState({});
    const [generalData, setGeneralData] = useState([]);
    const [businessgoalIndex, setBusinessgoalIndex] = useState();
    const [graphTransactionCount, setGraphTransactionCount] = useState();
    const [graphCustomerCount, setGraphCustomerCount] = useState();
    const [graphTCWithoutGoal, setGraphTCWithotGoal] = useState();
    const [graphCCWithoutGoal, setGraphCCWithotGoal] = useState();
    const [predictedData, setPredictedData] = useState([]);
    const [historicalData, setHistoricalData] = useState([]);
    const [histPredData, setHistPredData] = useState([]);
    const [growthAvailable, setSrowthAvailable] = useState([]);

    const { tags, disable, goals, data } = state;
    useEffect(() => {
        userJourny();
    }, []);
    const userJourny = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const Id = localStorage.getItem("Id");
        await getUserJourneyReport(token?.access_token, Id).then((res)=>{
            setFormdataGraph(res);
        }).catch((err)=>console.log(err.message));
        setLoader(false);
    };

    useEffect(() => {
        fetchPredictedData();
        fetchHistoricalData();
    }, []);

    useEffect(() => {
        mergedBothData();
    }, [historicalData, predictedData]);

    const fetchPredictedData = async () => {
        let id = decrypt(localStorage.getItem("schemaName")) == "Restaurant" ? "5814" : decrypt(localStorage.getItem("schemaName")) == "Travel" ? "4722" : "4722";
        let data = await growthprospect(id);
        if (data?.data) {
            const lastIndex = data?.data?.length - 1;
            const lastValue = (await data?.data) && data?.data[lastIndex];
            let allMonths = Array.from({ length: lastValue.month }, (_, i) => i + 1);
            let tempPredictedData = allMonths.map((month) => {
                let matchingData = data?.data?.find((d) => parseInt(d.month) == month);
                return matchingData ? matchingData.transactionAmount : 0;
            });
            setPredictedData(tempPredictedData);
        }
    };

    const fetchHistoricalData = async () => {
        let id = decrypt(localStorage.getItem("schemaName")) == "Restaurant" ? "5814" : decrypt(localStorage.getItem("schemaName")) == "Travel" ? "4722" : "4722";
        let data = await totalspend(id);
        let tempHistoricalData = [];
        data?.data?.filter((e) => {
            if (e.year == "2023") {
                tempHistoricalData.push(e.transactionAmount);
            }
        });
        setHistoricalData(tempHistoricalData);
    };

    const mergedBothData = () => {
        if (historicalData?.length && predictedData?.length) {
            const tempPredictedData = Array.from(predictedData);
            let startIndex = historicalData.length;
            let endIndex = predictedData.length;
            let temp = tempPredictedData.splice(startIndex, endIndex - startIndex + 1);
            setHistPredData(historicalData.concat(temp));
            setSrowthAvailable(percent(historicalData.concat(temp), 110));
        }
    };

    useEffect(() => {
        if (formDataGraph[0]?.Monthwisetrn_count) {
            const asArray = Object.entries(formDataGraph[0]?.Monthwisetrn_count).map((arr) => {
                const data = arr[0];
                return data.split("-")[1];
            });
            function onlyUnique(value, index, array) {
                return array.indexOf(value) === index;
            }
            var uniques = asArray.filter(onlyUnique);
            filterData(uniques[0], formDataGraph[0]?.Monthwisetrn_count).then((res) => {
                setReport1(res);
            });
        }
    }, [formDataGraph]);

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        setState({ ...state, data: BusinessGoal });
    }, [BusinessGoal]);

    useEffect(() => {
        const industry = decrypt(localStorage.getItem("schemaName"));
        data.map((item) => {
            if (item.industry === industry) {
                setState({ ...state, goals: item.goals });
            }
        });
    }, [data]);

    const fetch = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        await clearBusinessGoal();
        await getBusinessGoal(token?.access_token);
        setLoader(false);
    };

    const percent = (arr, per) => {
        for (var i = 0; i < arr.length; i++) {
            // if (i < 4) arr[i] = 0;
            arr[i] = (arr[i] * per) / 100 + arr[i];
        }
        return arr;
    };

    const handleChange = async (e, tag, index) => {
        var data;
        data = [...predictedData];
        if (decrypt(localStorage.getItem("schemaName")) == "Restaurant") {
            setReport2(percent(data, index == 0 ? 20 : index == 1 ? 30 : index == 2 ? 25 : index == 3 ? 40 : index == 4 ? 35 : 32));
        } else if (decrypt(localStorage.getItem("schemaName")) == "Travel") {
            setReport2(percent(data, index == 0 ? 20 : index == 1 ? 30 : index == 2 ? 25 : index == 3 ? 40 : index == 4 ? 35 : index == 5 ? 30 : index == 6 ? 25 : 45));
        } else {
            setReport2(percent(data, index == 0 ? 20 : index == 1 ? 30 : index == 2 ? 25 : index == 3 ? 40 : index == 4 ? 35 : 32));
        }
        settTitle(tag.businessgoal);
        let temp = tag.businessgoal;
        let businessGoal = temp.replace(/-/g, " ");
        setState({ ...state, tags: [`business goal:${businessGoal.toLowerCase()}`], disable: false });
        setBusinessgoalIndex(Number(index) + 1);
    };

    const handleClick = async () => {
        await clearCampaigns();
        navigate("/sample-campaigns", { state: tags });
    };
    const handleSkipClick = async () => {
        await clearCampaigns();
        navigate("/sample-campaigns", { state: [], skip: true });
    };

    const filterData = async (data, object) => {
        const asArray = await Object.entries(object);
        let filtered = await asArray.filter(([key, value]) => key.split("-")[1] === data);
        let year = filtered[0];
        if (year && year[0]) {
            year = year[0].split("-")[1];
        }

        let allMonths = Array.from({ length: 12 }, (_, i) => i + 1);
        let mergedArray = allMonths.map((month) => {
            let matchingData = filtered.find((d) => parseInt(d[0].split("-")[0]) == month);
            return matchingData ? matchingData : [`${month}-${year}`, 0];
        });

        const n = mergedArray.length;
        for (let i = 0; i < n - 1; i++) {
            for (let j = 0; j < n - i - 1; j++) {
                const monthA = parseInt(mergedArray[j][0].split("-")[0]);
                const monthB = parseInt(mergedArray[j + 1][0].split("-")[0]);
                if (monthA > monthB) {
                    const temp = mergedArray[j];
                    mergedArray[j] = mergedArray[j + 1];
                    mergedArray[j + 1] = temp;
                }
            }
        }
        const tempdata = await mergedArray.map((arr) => {
            return arr[1];
        });
        return tempdata;
    };

    useEffect(() => {
        const uniqueYears = [...new Set(IDBIMccData.map((item) => item.Year))];
        const datat = IDBIMccData.filter((data) => data.Year == 2023);
        let allMonths = Array.from({ length: 12 }, (_, i) => i + 1);
        let mergedArray = allMonths.map((month) => {
            let matchingData = datat?.find((d, i) => d.month == month);
            return matchingData
                ? matchingData
                : {
                      Year: 2023,
                      avg: "0",
                      count: "0",
                      id: "0",
                      max: "0",
                      min: "0",
                      month: month,
                  };
        });
        setAmount(
            mergedArray.map((data) => {
                return data.transactionAmount;
            })
        );
    }, []);

    const LineChartAnalyticalReport = useMemo(() => {
        return <LineChartAnalyticalReport2 data={Report2} amount={amount} title={title} predictedData={predictedData?.length ? predictedData : []} histPredData={histPredData?.length ? histPredData : []} growthAvailable={growthAvailable?.length ? growthAvailable : []} />;
    }, [, Report2, amount, title, , predictedData, histPredData, growthAvailable]);

    useEffect(() => {
        transactionCount();
        customerCount();
    }, [businessgoalIndex]);

    const transactionCount = () => {
        const merchant_code = decrypt(localStorage.getItem("merchantcode")); //"MER000003";

        let tempGoal = [];
        let tempTransactionCount = [];
        TransactionCount.filter((e) => {
            if (merchant_code === e.merchant_code) {
                tempGoal.push(e.goal);
            }
        });
        tempGoal.forEach((e) =>
            e.filter((arr, index) => {
                if (businessgoalIndex === Number(index) + 1) {
                    tempTransactionCount.push(arr);
                }
            })
        );
        setGraphTransactionCount(tempTransactionCount[0]);
    };

    const customerCount = () => {
        const merchant_code = decrypt(localStorage.getItem("merchantcode")); //"MER000003";

        let tempGoal = [];
        let tempCustomerCount = [];
        CustomerCount.filter((e) => {
            if (merchant_code === e.merchant_code) {
                tempGoal.push(e.goal);
            }
        });
        tempGoal.forEach((e) =>
            e.filter((arr, index) => {
                if (businessgoalIndex === Number(index) + 1) {
                    tempCustomerCount.push(arr);
                }
            })
        );
        setGraphCustomerCount(tempCustomerCount[0]);
    };

    useEffect(() => {
        if (!businessgoalIndex) {
            withoutBusinessGoalTransaction();
            withoutBusinessGoalCustomer();
        }
    }, []);
    const withoutBusinessGoalTransaction = () => {
        let merchant_code = decrypt(localStorage.getItem("merchantcode")); //"MER000003";
        let tempGoal = [];
        let tempTransactionCount = [];
        TransactionCount.filter((e) => {
            if (merchant_code === e.merchant_code) {
                tempGoal.push(e.goal);
            }
        });
        tempGoal.forEach((e) =>
            e.filter((arr, index) => {
                if (index == 0 || index == 1 || index == 2) {
                    tempTransactionCount.push(arr[index]);
                }
            })
        );
        setGraphTCWithotGoal(tempTransactionCount);
    };
    const withoutBusinessGoalCustomer = () => {
        let merchant_code = decrypt(localStorage.getItem("merchantcode")); // "MER000003";
        let tempGoal = [];
        let tempCustomerCount = [];
        CustomerCount.filter((e) => {
            if (merchant_code === e.merchant_code) {
                tempGoal.push(e.goal);
            }
        });
        tempGoal.forEach((e) =>
            e.filter((arr, index) => {
                if (index == 0 || index == 1 || index == 2) {
                    tempCustomerCount.push(arr[index]);
                }
            })
        );
        setGraphCCWithotGoal(tempCustomerCount);
    };

    return (
        <>
            <Header name={"Growth Prospect"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="analyticalReport">
                <div className="analyticalReportRFMAnalysis">
                    <div className="analyticalReportRFMAnalysis__topSection analyticalReportRFMAnalysis__topSection__gp">
                        <div className="container">
                            <div className="analyticalReportRFMAnalysis__leftSection">
                                <div className="dashboard__headingBackLink dashboard__headingBackLink2">
                                    {location?.state?.prevPath && (
                                        <Link to={"/upload-existing-data"}>
                                            <img style={{ width: "18px" }} src={backIcon2} alt="" />
                                            <span>Back</span>
                                        </Link>
                                    )}
                                </div>
                                <p>Growth prospects refer to the potential opportunities for expanding the customer base, increasing sales, and generating higher revenue in the future. With Novus, identify new markets or customer segments and create effective marketing strategies to attract and retain customers.</p>
                            </div>
                        </div>
                    </div>
                    <div className="analyticalReportChartAnalysisTwo">
                        <div className="container">
                            <div className="analyticalReportChartAnalysisTwo__bottomSection">
                                <div className="analyticalReportChartAnalysisTwo__heading">{/* <h2>Growth Prospect</h2> */}</div>
                                <div className="analyticalReportChartAnalysisTwo__leftSection">
                                    <div className="analyticalReportChartAnalysisTwo__box">
                                        <div className="analyticalReportChartAnalysisTwo__header">
                                            <div className="analyticalReportChartAnalysisTwo__left">
                                                <div className="analyticalReportChartAnalysisTwo__image">
                                                    <img src={dashboardIconTwo} alt="" />
                                                </div>
                                                <div className="analyticalReportChartAnalysisTwo__details">
                                                    <h3>Total Spend</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="analyticalReportChartAnalysisTwo__chartSection">{LineChartAnalyticalReport}</div>
                                    </div>
                                </div>
                                <div className="analyticalReportChartAnalysisTwo__rightSection analyticalReportChartAnalysisTwo__rightSection__gp">
                                    <div className="businessGoalsSelection__bottomSection">
                                        <h5>How different campaign can accelerate your growth</h5>
                                        <div className="">
                                            {Array.isArray(goals) ? (
                                                <Accordion className="businessGoalsSelection__accordion" onSelect={(e) => setActiveKey(e)}>
                                                    {goals &&
                                                        goals.map((item, index) => (
                                                            <Card key={index} className="businessGoalsSelection__accordionItem">
                                                                <Card.Header className="businessGoalsSelection__accordionHeading">
                                                                    <div className={index == activeKey ? "businessGoalsSelection__accordionButton active" : "businessGoalsSelection__accordionButton"}>
                                                                        <input type="radio" name="businessgoal" value={index} onChange={(e) => handleChange(e, item, e.target.value)} />
                                                                        {item.businessgoal}
                                                                    </div>
                                                                </Card.Header>
                                                                {/* <Accordion.Collapse className="businessGoalsSelection__accordionCollapse" eventKey={index.toString()}>
                                                                    <Card.Body className="businessGoalsSelection__accordionBody">{item.description}</Card.Body>
                                                                </Accordion.Collapse> */}
                                                            </Card>
                                                        ))}
                                                </Accordion>
                                            ) : (
                                                <h6>There is no business goal</h6>
                                            )}
                                            <div className="businessGoalsSelection__buttonSection businessGoalsSelection__buttonSectiongp">
                                                <div className="businessGoalsSelection__buttonSectionLeft">
                                                    <Link onClick={handleSkipClick}>Skip</Link>
                                                </div>
                                                <div className="businessGoalsSelection__buttonSectionRight">
                                                    <Link to="/contact-us" className="btn btn-outline-primary">
                                                        Contact Support
                                                    </Link>
                                                    <button className="btn btn-primary" disabled={disable ? true : false} onClick={handleClick}>
                                                        Select Campaigns
                                                        <i className="fa-solid fa-arrow-right"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="analyticalReportChartAnalysisOne__bottomSection">
                                    <div className="analyticalReportChartAnalysisOne__heading">
                                        <h2>Customer Spending Overview</h2>
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__leftSection">
                                        <div className="analyticalReportChartAnalysisOne__box">
                                            <div className="analyticalReportChartAnalysisOne__header">
                                                <div className="analyticalReportChartAnalysisOne__left">
                                                    <div className="analyticalReportChartAnalysisOne__image">
                                                        <img src={dashboardIconThree} alt="" />
                                                    </div>
                                                    <div className="analyticalReportChartAnalysisOne__details">
                                                        <h3> Customers Count</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__chartSection">
                                                <BarChartAnalyticalReportGrowth goalData={graphCustomerCount} withoutGoal={graphCCWithoutGoal} title={"Customer Count"} backgroundColor={"rgba(53, 162, 235, 0.5)"} />
                                            </div>
                                        </div>

                                        {/* <div className="analyticalReportChartAnalysisOne__box">
                                            <div className="analyticalReportChartAnalysisOne__header">
                                                <div className="analyticalReportChartAnalysisOne__left">
                                                    <div className="analyticalReportChartAnalysisOne__image">
                                                        <img src={dashboardIconThree} alt="" />
                                                    </div>
                                                    <div className="analyticalReportChartAnalysisOne__details">
                                                        <h3>Transactions</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__reportSection">
                                                <h3>{formDataGraph[0]?.Totaltrn ? formDataGraph[0]?.Totaltrn : 0}</h3>
                                            </div>
                                        </div> */}
                                        {/* <div className="analyticalReportChartAnalysisOne__box">
                                            <div className="analyticalReportChartAnalysisOne__header">
                                                <div className="analyticalReportChartAnalysisOne__left">
                                                    <div className="analyticalReportChartAnalysisOne__image">
                                                        <img src={dashboardIconTwo} alt="" />
                                                    </div>
                                                    <div className="analyticalReportChartAnalysisOne__details">
                                                        <h3>Customer Average Spend</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__reportSection">
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="analyticalReportChartAnalysisOne__rightSection">
                                        <div className="analyticalReportChartAnalysisOne__box">
                                            <div className="analyticalReportChartAnalysisOne__header">
                                                <div className="analyticalReportChartAnalysisOne__left">
                                                    <div className="analyticalReportChartAnalysisOne__image">
                                                        <img src={dashboardIconOne} alt="" />
                                                    </div>
                                                    <div className="analyticalReportChartAnalysisOne__details">
                                                        <h3>Transactions Count</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="analyticalReportChartAnalysisOne__chartSection">
                                                <BarChartAnalyticalReport1 data={Report1} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {location?.state?.prevPath && (
                                    <div className="analyticalReportChartAnalysisTwo__rightSection">
                                        <div className="analyticalReportChartAnalysisTwo__paragraph">
                                            <p>With the Help of AI and existing data, use Novus Loyalty to set up campaign to supercharge your growth.</p>
                                        </div>
                                        <div className="analyticalReportChartAnalysisTwo__buttonSection">
                                            <Link to="/business-goals-selection" className="btn btn-primary">
                                                Select Your Business Goals
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </Link>

                                            <Link to="/campaign-selection" state={{ path: "/analytical-report" }} className="btn btn-link">
                                                Skip
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

const mapStateToProps = (state) => ({
    BusinessGoal: state.BusinessGoal.data,
    Campaigns: state.Campaigns.data,
});
const mapDispatchToProps = {
    getBusinessGoal,
    clearBusinessGoal,
    clearCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(GrowthProspect);
