export const OFFER_COLUMN = [
    {
        Header: "Offer Title",
        Footer: "Offer Title",
        accessor: "offertitle",
        sticky: "left",
    },
    {
        Header: "Description",
        Footer: "Description",
        accessor: "offerdescription",
    },
    {
        Header: "Offer Type",
        Footer: "Offer Type",
        accessor: "offertype",
    },
    {
        Header: "Start Date",
        Footer: "Start Date",
        accessor: "startDate",
    },
    {
        Header: "End Date",
        Footer: "End Date",
        accessor: "endDate",
    },
    {
        Header: "Eligible Products/Services",
        Footer: "Eligible Products/Services",
        accessor: "product_services",
    },
    {
        Header: "Customer Eligibility",
        Footer: "Customer Eligibility",
        accessor: "customerEligibility",
    },
    {
        Header: "Redemption Instructions",
        Footer: "Redemption Instructions",
        accessor: "redemptionInstructions",
    },
    {
        Header: "Image Path",
        Footer: "Image Path",
        accessor: "imageAction",
    },
    {
        Header: "Status",
        Footer: "Status",
        accessor: "status",
    },
    {
        Header: "Delete",
        Footer: "Delete",
        accessor: "delete",
    },
];
