import React, { useEffect, useRef, useState } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { Link, useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import Header from "../layout/Header";
import { backIcon2 } from "../../Image/Index";
import TextAreaValidator from "../partial/TextAreaValidator";
import TextValidator from "../partial/TextValidator";
import SelectValidator from "../partial/SelectValidator";
import { getDate, parseNumeric } from "../../utils/helper";
import { decrypt } from "../../utils/encodingdecoding";
import { selectValueGetter } from "../../utils/helper";
import moment from "moment";
import { createVoucherTemplate, getVoucherEnquiry, updateVoucherTemplate } from "../pages/campaigns/services";
import { deleteVoucherImageData, getVouchersImage } from "../../actions/auth";
import { vouchersImage } from "../../actions/setting";

const Voucher1 = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [voucherCode, setVoucherCode] = useState();
    const [voucherName, setVoucherName] = useState();
    const [responseImages, setResponseImages] = useState();
    const [loader, setLoader] = useState(false);
    const [bannerSize, setBannerSize] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const imageInputRefBanner = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const [options] = useState([
        {
            value: "currency",
            label: "Currency",
        },
        {
            value: "discount",
            label: "Discount",
        },
        {
            value: "point",
            label: "Point",
        },
    ]);

    const [discountOption] = useState([
        {
            value: "fix",
            label: "Fix",
        },
        {
            value: "percentage",
            label: "Percentage",
        },
    ]);

    const [voucherValueOption] = useState([
        {
            value: "No Value",
            label: "No Value",
        },
        {
            value: "Fix Value",
            label: "Fix Value",
        },
        {
            value: "Pre Loaded Value",
            label: "Pre Loaded Value",
        },
    ]);

    const [state, setState] = useState({
        name: "",
        code: "",
        voucherValidityDate: {
            startDate: "",
            endDate: "",
        },
        voucherVisibilityDate: "",
        voucherValueType: "Pre Loaded Value",
        redeemUrl: "",
        termsAndCondition: "",
        perPersonRedemption: 0,
        perPersonRedemptionCount: 0,
        totalRedemption: 0,
        merchant: 0,
        imageUpload: "",
        voucherGeneratedType: 0,
        voucherType: 0,
        title: "",
        description: "",
        isActive: 0,
        internalTags: [],
        numberOfVouchers: 1,
        templateHitCounter: 0,
        lastGenratedNumberOfVoucher: 0,
        usedVoucherCount: 0,
        merchantCode: [],
        generatePath: "",
        importVoucher: [
            {
                totalCount: 0,
                successVoucherCount: 0,
                unSuccessVoucherCount: 0,
                createdDate: getDate(new Date()),
                blobFileName: {
                    fileName: "",
                    success: "",
                    unSuccess: "",
                },
            },
        ],
        uploadStatus: "",
        earningType: "",
        discountType: "",
        voucherValueType: "",
        voucherValue: 0,
        voucherType: 0,
        nxpoints: 0,
        totalRedemptionLimit: 0,
        merchantId: [decrypt(localStorage.getItem("merchantcode"))],
        merchantCode: [decrypt(localStorage.getItem("merchantcode"))],

        tags: [],
    });

    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [voucherVisibileError, setVoucherVisibileError] = useState(false);
    const [createdDate, setCreatedDate] = useState();
    const { code, name, voucherValidityDate, title, perPersonRedemption, perPersonRedemptionCount, isActive, tags, voucherGeneratedType, numberOfVouchers, voucherVisibilityDate, voucherType, totalRedemption, earningType, voucherValue, description, nxpoints, redeemUrl, termsAndCondition, totalRedemptionLimit, discountType, voucherValueType } = state;
    const [resetdata, setResetData] = useState();
    const { startDate, endDate } = voucherValidityDate;

    const handleData = async (data) => {
        const { code, name, voucherValidityDate, title, perPersonRedemption, perPersonRedemptionCount, isActive, voucherGeneratedType, imageUpload, numberOfVouchers, voucherVisibilityDate, voucherType, totalRedemption, earningType, voucherValue, description, nxpoints, redeemUrl, termsAndCondition, totalRedemptionLimit, createdDate, discountType, voucherValueType } = data;
        let updatedVoucherValueType = voucherValueType;
        if (voucherValue > 0 && voucherValueType !== "Pre Loaded Value") {
            updatedVoucherValueType = "Fix Value";
        }

        setResponseImages(data.imageUpload);

        setState((prevState) => ({
            ...prevState,
            code: code,
            name: name,
            voucherValidityDate: {
                ...prevState.voucherValidityDate,
                startDate: voucherValidityDate?.startDate,
                endDate: voucherValidityDate?.endDate,
            },

            isActive: isActive,
            perPersonRedemption: perPersonRedemption,
            voucherType: voucherType,
            perPersonRedemptionCount: perPersonRedemptionCount,
            voucherGeneratedType: voucherGeneratedType,
            imageUpload: imageUpload,
            numberOfVouchers: numberOfVouchers,
            voucherVisibilityDate: voucherVisibilityDate,
            totalRedemption: totalRedemption,
            earningType: earningType,

            voucherValueType: updatedVoucherValueType,
            discountType: discountType,
            voucherValue: voucherValue,
            description: description,
            redeemUrl: redeemUrl,
            title: title,
            termsAndCondition: termsAndCondition,
        }));
        setCreatedDate(getDate(createdDate, "YYYY-MM-DD"));
    };

    useEffect(() => {
        if (location.state) {
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const response = getVoucherEnquiry(token?.access_token, location?.state);
            response
                .then((res) => {
                    setResetData(res?.data);
                    handleData(res?.data);
                    setLoader(false);
                })
                .catch((err) => {
                    setLoader(false);
                });
            setLoader(false);
        }
    }, [location]);

    useEffect(() => {
        let tempEndDate = moment(endDate).diff(moment(startDate), "days");
        if (tempEndDate < 0) {
            setEndDateError(true);
        } else {
            setEndDateError(false);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        let tempVisibilityDate = moment(endDate).diff(moment(voucherVisibilityDate), "days");
        let tempStartDate = moment(voucherVisibilityDate).diff(moment(startDate), "days");
        if (tempStartDate < 0 || tempVisibilityDate < 0) {
            setVoucherVisibileError(true);
        } else {
            setVoucherVisibileError(false);
        }
    }, [endDate, voucherVisibilityDate, startDate]);

    useEffect(() => {
        if (location.state) {
            let tempStartDate = moment(startDate).diff(moment(createdDate), "days");
        }
    }, [startDate]);

    const handleToggle = (e) => {
        setState({ ...state, [e.target.name]: Number(e.target.checked) });
    };

    const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const handleDateTime = (e) => {
        setState((prevState) => ({
            ...prevState,
            voucherValidityDate: {
                ...prevState.voucherValidityDate,
                [e.target.name]: e.target.value,
            },
        }));
    };

    const onSelectChange = (e) => {
        let temp = e.value;
        setState({ ...state, earningType: temp });
    };

    const onSelectChangediscount = (e) => {
        let temp = e.value;
        setState({ ...state, discountType: temp });
    };
    const onSelectChangeVoucherValue = (e) => {
        let temp = e.value;
        setState({ ...state, voucherValueType: temp });
    };

    useEffect(() => {
        if (earningType === "discount") {
            setState({ ...state, voucherValueType: "Pre Loaded Value" });
        } else {
            setState({ ...state, voucherValueType: voucherValueType });
        }
    }, [earningType]);

    useEffect(() => {
        if (voucherValueType !== "Pre Loaded Value" || voucherValueType !== "Fix Value") {
            setState({ ...state, voucherValue: 0 });
        }
    }, [voucherValueType]);

    useEffect(() => {
        if (earningType != "discount") {
            setState({ ...state, discountType: null });
        }
    }, [earningType]);

    useEffect(() => {
        if (perPersonRedemption === 0) {
            setState({ ...state, perPersonRedemptionCount: 0 });
        }
    }, [perPersonRedemption]);

    useEffect(() => {
        if (voucherType === 0) {
            setState({ ...state, totalRedemption: 0 });
        }
    }, [voucherType]);

    const handleReset = () => {
        if(resetdata?.name?.length > 0 ){
            handleData(resetdata);
        }
        else{
            setState({
                name: "",
                code: "",
                voucherValidityDate: {
                    startDate: "",
                    endDate: "",
                },
                voucherVisibilityDate: "",
                redeemUrl: "",
                termsAndCondition: "",
                perPersonRedemption: 0,
                perPersonRedemptionCount: 0,
                totalRedemption: 0,
                merchant: 0,
                voucherGeneratedType: 0,
                voucherType: 0,
                title: "",
                description: "",
                isActive: 0,
                internalTags: [],
                numberOfVouchers: 1,
                templateHitCounter: 0,
                lastGenratedNumberOfVoucher: 0,
                usedVoucherCount: 0,
                merchantCode: [decrypt(localStorage.getItem("merchantcode"))],
                generatePath: "",
                importVoucher: [
                    {
                        totalCount: 0,
                        successVoucherCount: 0,
                        unSuccessVoucherCount: 0,
                        createdDate: getDate(new Date()),
                        blobFileName: {
                            fileName: "",
                            success: "",
                            unSuccess: "",
                        },
                    },
                ],
                uploadStatus: "",
                earningType: "",
                earningValue: "",
                nxpoints: 0,
                totalRedemptionLimit: 0,
                merchantId: [decrypt(localStorage.getItem("merchantcode"))],
                tags: [],
            });

        }
      
    };

    const onSubmit = async () => {
        if (endDateError === false && voucherVisibileError === false && startDateError === false) {
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            if (location.state) {
                let temp = location.state;
                let apiVoucherValueType = voucherValueType === "Fix Value" ? "No Value" : voucherValueType;

                const modifiedState = { ...state, voucherValueType: apiVoucherValueType };
                const response = await updateVoucherTemplate(token?.access_token, temp, modifiedState);
                setVoucherCode(temp);
                setVoucherName(state.name);
                if (response?.message === "Record Updated Successfully.") {
                    swal({
                        position: "center",
                        icon: "success",
                        title: "Voucher Template Updated Successfully",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                } else {
                    swal({
                        position: "center",
                        icon: "error",
                        title: response?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            } else {
                let apiVoucherValueType = voucherValueType === "Fix Value" ? "No Value" : voucherValueType;

                const modifiedState = { ...state, voucherValueType: apiVoucherValueType };
                const response = await createVoucherTemplate(token?.access_token, modifiedState);
                setVoucherCode(response?.data?.code);
                setVoucherName(response?.data?.name);
                if (response?.message === "Record Created Successfully.") {
                    swal({
                        position: "center",
                        icon: "success",
                        title: "Voucher Template Created Successfully",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    setState({
                        name: "",
                        code: "",
                        voucherValidityDate: {
                            startDate: "",
                            endDate: "",
                        },
                        voucherVisibilityDate: "",
                        redeemUrl: "",
                        termsAndCondition: "",
                        perPersonRedemption: 0,
                        perPersonRedemptionCount: 0,
                        totalRedemption: 0,
                        merchant: 0,
                        voucherGeneratedType: 0,
                        imageUpload: "",
                        voucherType: 0,
                        title: "",
                        description: "",
                        isActive: 0,
                        internalTags: [],
                        numberOfVouchers: 1,
                        templateHitCounter: 0,
                        lastGenratedNumberOfVoucher: 0,
                        usedVoucherCount: 0,
                        merchantCode: [],
                        generatePath: "",
                        importVoucher: [
                            {
                                totalCount: 0,
                                successVoucherCount: 0,
                                unSuccessVoucherCount: 0,
                                createdDate: getDate(new Date()),
                                blobFileName: {
                                    fileName: "",
                                    success: "",
                                    unSuccess: "",
                                },
                            },
                        ],
                        uploadStatus: "",
                        earningType: "",
                        voucherValueType: "",
                        discountType: "",
                        voucherValue: 0,
                        nxpoints: 0,
                        totalRedemptionLimit: 0,
                        merchantId: [decrypt(localStorage.getItem("merchantcode"))],
                        merchantCode: [decrypt(localStorage.getItem("merchantcode"))],
                        tags: [],
                    });
                } else {
                    swal({
                        position: "center",
                        icon: "error",
                        title: response?.message,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }
            setLoader(false);
        }
    };

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        if (voucherCode) {
            if (!bannerSize && selectedBanner !== null) {
                vouchersImage(token?.access_token, voucherCode, selectedBanner);
            }
            setTimeout(() => {
                navigate("/voucher-template");
            }, 2000);
        }
    }, [voucherCode, bannerSize, selectedBanner]);

    const handleFileBanner = (e) => {
        if (e.target.files[0] && e.target.files[0]?.bannerSize > 1 * 1024 * 1024) {
            setBannerSize(true);
        } else {
            const filedata = e.target.files[0];
            setSelectedBanner(filedata);
            setBannerSize(false);
        }
    };

    const handleShowDialog = () => {
        setIsOpen(!isOpen);
        window.scrollTo(0, 0);
    };

    const handleDelete = async (voucherTemplateCode) => {
        setLoader(true);
        let token = JSON.parse(localStorage.getItem("token_gen"));
        deleteVoucherImageData(token?.access_token, voucherTemplateCode)
            .then((Setting) => {
                swal({
                    position: "center",
                    icon: "success",
                    title: Setting.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
                setTimeout(() => {
                    navigate("/voucher-template");
                }, 2000);
                setLoader(false);
            })
            .catch((err) => {
                swal({
                    position: "center",
                    icon: "error",
                    title: err.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
            });
    };

    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <Header name={"Voucher Template"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__profileSettingsMain">
                        <div className="dashboard__heading">
                            <div className="dashboard__headingLeft">
                                <div className="dashboard__headingBackLink">
                                    <Link to="" onClick={() => navigate(-1)}>
                                        <img src={backIcon2} alt="" />
                                        <div className="dashboard__headingContent">
                                            <h3>Voucher Template</h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard__profileSettingsBox">
                            <ValidatorForm className="customForm customForm_2" onSubmit={onSubmit}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <div className="form-group">
                                                <label>
                                                    Name of the Voucher Template<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <TextValidator maxLength="50" className="form-control" name="name" value={name} onChange={(e) => onChange(e)} validators={["required", "matchRegexp:^[a-zA-Z0-9@$ ]*$"]} errorMessages={["Please enter name", "Only letters, numbers, @, and $ are allowed."]} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>
                                                Voucher Validity Start Date & Time<span style={{ color: "red" }}>*</span>
                                            </label>
                                            {location.state ? <TextValidator className="form-control" name="startDate" type="date" value={getDate(startDate, "YYYY-MM-DD")} onChange={handleDateTime} validators={["required"]} errorMessages={["Please enter startDate & Time"]} /> : <TextValidator className="form-control" name="startDate" type="date" value={getDate(startDate, "YYYY-MM-DD")} min={getDate(new Date(), "YYYY-MM-DD")} onChange={handleDateTime} validators={["required"]} errorMessages={["Please enter startDate & Time"]} />}
                                            {startDateError && <span style={{ color: "red" }}>Start date should not be less than {getDate(createdDate, "DD-MM-YYYY")}</span>}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>
                                                Voucher Validity End Date & Time<span style={{ color: "red" }}>*</span>
                                            </label>
                                            <TextValidator className="form-control" name="endDate" type="date" value={getDate(endDate, "YYYY-MM-DD")} onChange={handleDateTime} validators={["required"]} errorMessages={["Please enter endDate & Time"]} />
                                            {endDateError && <span style={{ color: "red" }}>End date should be greater than start date</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row voucher_box_row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <div className="form-group">
                                                <label>
                                                    Earning Type<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <SelectValidator placeholder="Select Type" className="custom-ReactSelect bordered" name="earningType" value={selectValueGetter(options, earningType) || ""} options={options} validators={["required"]} errorMessages={["Please enter earning type"]} onChange={onSelectChange} />
                                            </div>
                                        </div>
                                    </div>
                                    {earningType === "discount" && (
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group withIcon">
                                                <label>Discount Type</label>
                                                <SelectValidator placeholder="Select Type" className="custom-ReactSelect bordered" name="discountType" value={selectValueGetter(discountOption, discountType) || ""} options={discountOption} onChange={onSelectChangediscount} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row voucher_box_row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <div className="form-group">
                                                <label>
                                                    Voucher Value Type<span style={{ color: "red" }}>*</span>
                                                </label>

                                                <SelectValidator placeholder="Select Type" className="custom-ReactSelect bordered" name="voucherValueType" value={selectValueGetter(voucherValueOption, voucherValueType) || ""} options={voucherValueOption} validators={["required"]} errorMessages={["Please enter voucher value type"]} onChange={onSelectChangeVoucherValue} />
                                            </div>
                                        </div>
                                    </div>

                                    {(voucherValueType === "Pre Loaded Value" || voucherValueType === "Fix Value") && (
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group withIcon">
                                                <label>
                                                    Voucher Value<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <TextValidator
                                                    className="form-control"
                                                    name="voucherValue"
                                                    value={voucherValue}
                                                    validators={voucherValueType === "Pre Loaded Value" ? ["required", "matchRegexp:^[0-9,]*$"] : ["required"]}
                                                    errorMessages={voucherValueType === "Pre Loaded Value" ? ["Please enter voucher value", "Please enter a valid value"] : ["Please enter voucher value"]}
                                                    maxLength={voucherValueType !== "Pre Loaded Value" && "7"}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            voucherValue: voucherValueType === "Pre Loaded Value" ? e.target.value : parseNumeric(e.target.value),
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row voucher_box_row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="person" style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                            <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
                                                Available For
                                            </p>
                                            <div className="">
                                                <label className="toggle">
                                                    <input type="checkbox" name="voucherType" checked={voucherType} onChange={handleToggle} />
                                                    <span className="slider"></span>
                                                    <span className="labels" data-on="Open For All" data-off="Campaign"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {voucherType === 1 && (
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group withIcon">
                                                <label>Total Redemption</label>
                                                <TextValidator
                                                    className="form-control"
                                                    name="totalRedemption"
                                                    value={totalRedemption}
                                                    validators={["required"]}
                                                    errorMessages={["Please enter total redemption"]}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            totalRedemption: Number(parseNumeric(e.target.value)),
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row voucher_box_row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Tagline</label>
                                            <TextValidator className="form-control" name="title" value={title} onChange={(e) => onChange(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="person" style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                            <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
                                                Redemption Limit
                                            </p>
                                            <div className="">
                                                <label className="toggle">
                                                    <input type="checkbox" name="perPersonRedemption" checked={perPersonRedemption} onChange={handleToggle} />
                                                    <span className="slider"></span>
                                                    <span className="labels" data-on="Multiple" data-off="One Time"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {perPersonRedemption === 1 && (
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group withIcon">
                                                <label>Per Person Redemption Count</label>
                                                <TextValidator
                                                    className="form-control"
                                                    name="perPersonRedemptionCount"
                                                    value={perPersonRedemptionCount}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            perPersonRedemptionCount: Number(parseNumeric(e.target.value)),
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row voucher_box_row" style={{ padding: "20px 0" }}>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>
                                                Voucher Visibile From Date & Time<span style={{ color: "red" }}>*</span>
                                            </label>
                                            <TextValidator className="form-control" name="voucherVisibilityDate" value={getDate(voucherVisibilityDate, "YYYY-MM-DD")} type="date" onChange={(e) => onChange(e)} validators={["required"]} errorMessages={["Please enter voucher template visible date"]} />
                                            {voucherVisibileError && <span style={{ color: "red" }}> Voucher visibility date should be in between voucher validity start & end date </span>}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="person" style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                            <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
                                                Voucher Mode
                                            </p>
                                            <div className="">
                                                <label className="toggle">
                                                    <input type="checkbox" name="voucherGeneratedType" checked={voucherGeneratedType} onChange={handleToggle} disabled={location.state ? true : false} />
                                                    <span className="slider"></span>
                                                    <span className="labels" data-on="Generated" data-off="Imported"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {voucherGeneratedType === 1 && (
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group withIcon">
                                                <label>Generate No Of Vouchers At One Time</label>
                                                <TextValidator
                                                    className="form-control"
                                                    name="numberOfVouchers"
                                                    value={numberOfVouchers}
                                                    validators={["required"]}
                                                    maxLength="7"
                                                    errorMessages={["Please enter generate number of vouchers at one time"]}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            numberOfVouchers: Number(parseNumeric(e.target.value)),
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row voucher_box_row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Redeem URL</label>
                                            <TextValidator className="form-control" name="redeemUrl" maxLength="500" value={redeemUrl} onChange={(e) => onChange(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Terms And Conditions</label>
                                            <TextValidator className="form-control" name="termsAndCondition" value={termsAndCondition} onChange={(e) => onChange(e)} />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="form-group withIcon">
                                            <label>Description of the Voucher Template</label>
                                            <TextAreaValidator className="form-control" maxLength="500" name="description" value={description} onChange={(e) => onChange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row voucher_box_row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        {responseImages ? (
                                            <div className="form-group withIcon">
                                                <label>
                                                    Upload Image<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input className="form-control" type="file" ref={imageInputRefBanner} accept={"image/png, image/gif, image/jpeg, image/jpg"} onChange={handleFileBanner} />
                                                {bannerSize && <span style={{ color: "red" }}>file size not more than 1 MB</span>}
                                            </div>
                                        ) : (
                                            <div className="form-group withIcon">
                                                <label>Upload Image</label>
                                                <input className="form-control" type="file" ref={imageInputRefBanner} accept={"image/png, image/gif, image/jpeg, image/jpg"} onChange={handleFileBanner} />
                                                {bannerSize && <span style={{ color: "red" }}>file size not more than 1 MB</span>}
                                            </div>
                                        )}
                                    </div>
                                    {responseImages ? (
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 v_imagemainbox">
                                            <div className="v_imagebox">
                                                <img className="small" src={responseImages} style={{ cursor: "pointer" }} onClick={handleShowDialog} width={50} />
                                                <Link onClick={() => handleDelete(code)}>
                                                    {" "}
                                                    <i className="fa fa-times-circle"></i>
                                                </Link>
                                            </div>
                                            {isOpen && (
                                                <dialog className="dialog" open onClick={handleShowDialog}>
                                                    <button>X</button>
                                                    <img className="image" src={responseImages} onClick={handleShowDialog} />
                                                </dialog>
                                            )}
                                        </div>
                                    ) : null}
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                        <div className="person" style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                            <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
                                                Status
                                            </p>
                                            <div className="">
                                                <label className="toggle">
                                                    <input type="checkbox" name="isActive" checked={isActive} onChange={handleToggle} />
                                                    <span className="slider"></span>
                                                    <span className="labels" data-on="Active" data-off="Inactive"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12  col-md-12 col-lg-12 col-xl-12">
                                        <div className="button-section">
                                            <button type="submit" className="btn btn-primary">
                                                Submit
                                            </button>
                                            <button type="button" className="btn btn-danger" onClick={handleReset}>
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Voucher1;
