import React, { useEffect, useState } from 'react'
import { ValidatorForm } from 'react-form-validator-core';
import Header from '../../layout/Header';
import TextValidator from '../../partial/TextValidator';
import TextAreaValidator from "../../partial/TextAreaValidator"
import { QueryBuilder, formatQuery } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css'
import swal from 'sweetalert';
import SelectValidator from '../../partial/SelectValidator';
import Switch from "react-switch";
import { getOperator, selectValueGetter } from '../../../utils/helper';
import { decrypt } from '../../../utils/encodingdecoding';
import { schema } from '../campaigns/services';
import { createTier } from '../../../actions/auth';

function Tiers() {
    const [loader, setLoader] = useState(false)
    const initialQueryUE = {
        combinator: 'and',
        rules: [],
        sqlFilter: ""
    };
    const initialQueryUF = {
        combinator: 'and',
        rules: [],
    };
    const initialQueryDE = {
        combinator: 'and',
        rules: [],
        sqlFilter: ""
    };
    const initialQueryDF = {
        combinator: 'and',
        rules: [],
    };
    // const [clientOption, setClientOption] = useState([{ value: "merchant", label: "merchant", }])
    // const [schemaOpt, setSchemaOpt] = useState([{ value: "sanity 220", label: "sanity", }])
    const [durationOpt, setDurationOpt] = useState([
        { value: "Day", label: "15 Day" },
        { value: "Quarterly", label: "Quarterly" },
        { value: "Monthly", label: "Monthly" }
    ])
    const operatorOpt = [
        { operator: "equal", label: "Equal", value: "=" },
        { operator: "!=", label: "Not Equal", value: "not_equal" },
        { operator: "<", label: "Less", value: "less" },
        { operator: "<=", label: "Less Or Equal", value: "less_or_equal" },
        { operator: ">", label: "Greater", value: "greater" },
        { operator: ">=", label: "Greater Or Equal", value: "greater_or_equal" },
        { operator: "between", label: "Between", value: "between" },
        { operator: "contains", label: "Contains", value: "contains" },
        { operator: "beginsWith", label: "Contains", value: "beginsWith" },
        { operator: "endsWith", label: "Contains", value: "endsWith" },
        { operator: "doesNotContain", label: "Contains", value: "doesNotContain" },
        { operator: "doesNotBeginWith", label: "Contains", value: "doesNotBeginWith" },
        { operator: "doesNotEndWith", label: "Contains", value: "doesNotEndWith" },
        { operator: "null", label: "Contains", value: "null" },
        { operator: "in", label: "Contains", value: "in" },
        { operator: "notIn", label: "Contains", value: "notIn" },
        { operator: "notBetween", label: "Contains", value: "notBetween" },
    ]

    const initialState = {
        name: "",
        client: "",
        status: false,
        alert: false,
        tags: "",
        description: "",
        upgradeToggle: false,
        downgradeToggle: false,
        upgradeDuration: "",
        downgradeDuration: "",
        upgradeRule: [
            {
                id: 1,
                existingRule: initialQueryUE,
                functionRule: initialQueryUF
            }
        ],
        downgradeRule: [
            {
                id: 1,
                existingRule: initialQueryDE,
                functionRule: initialQueryDF
            }
        ],
    }
    const [state, setState] = useState(initialState)
    const [data, setData] = useState()
    const [transSchema, setTransSchema] = useState()
    const { name, client, status, alert, description, upgradeRule, downgradeRule, upgradeToggle, downgradeToggle, upgradeDuration, downgradeDuration } = state

    const fields = data?.length > 0 && data?.map((item) => {
        if (item?.fielddatatype === 'Enumeration') {
            return {
                name: item?.key,
                label: item?.fielddisplayname,
                type: item?.type,
                valueEditorType: 'select',
                values: [
                    { value: '', label: 'Select', key: 'select-option' },
                    ...item?.enum.map((i, index) => ({ value: i, label: i, key: index }))
                ]
            };
        }
        else {
            return {
                name: item?.key,
                label: item?.fielddisplayname,
                type: item?.type,
            };
        }
    });

    const fetchSchema = async () => {
        setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        let schemaName = localStorage.getItem("schemaName");
        schemaName = schemaName.split(" ").join("");
        const response = await schema(token?.access_token, schemaName);
        if (response.message === "Request processed successfully.") {
            let newdata = response?.data?.schema?.properties;
            let temp = [];
            let flag = 1;
            for (const property in newdata) {
                if (newdata[property].isrulesupported === true && property !== "transactionType") {
                    if (newdata[property].fielddatatype === "Enumeration") {
                        temp.push({
                            key: property,
                            fielddisplayname: newdata[property].fielddisplayname,
                            type: newdata[property].type,
                            enum: newdata[property].enum,
                            fielddatatype: newdata[property].fielddatatype
                        })
                    } else {
                        temp.push({
                            key: property,
                            fielddisplayname: newdata[property].fielddisplayname,
                            type: newdata[property].type,
                        })
                    }
                }
                flag += 1;
            }
            setTransSchema(response.data.transactionSchemaCode)
            setData(temp)
            setLoader(false)
        }
    };

    useEffect(() => {
        fetchSchema();
    }, []);

    function manipulateQuery(newQuery) {
        const transformRules = (rules, flag) => {
            return rules.map(rule => {
                if (rule.rules) {
                    return {
                        condition: rule.combinator ? rule.combinator.toUpperCase() : "AND",
                        rules: transformRules(rule.rules)
                    };
                } else {
                    const op = getOperator(operatorOpt, rule.operator);
                    let tempType
                    fields?.filter(f => {
                        if (f.name === rule.field) {
                            tempType = f.type
                        }
                    })
                    return {
                        id: flag === "existing" ? rule.field : "Functions",
                        field: rule.field,
                        type: tempType,
                        input: tempType === ("string" || "boolean") ? "text" : "integer",
                        operator: op?.operator || rule.operator,
                        value: rule.value,
                        data: {
                            class: "Customer"
                        }
                    };
                }
            });
        };

        const transformedQuery = newQuery.map((ele) => {
            const { id, existingRule, functionRule, ...rest } = ele;
            return {
                ...rest,
                existingRule: {
                    condition: ele.combinator ? ele.combinator.toUpperCase() : "AND",
                    rules: transformRules(existingRule.rules, "existing")
                },
                functionRule: {
                    condition: ele.combinator ? ele.combinator.toUpperCase() : "AND",
                    rules: transformRules(functionRule.rules, "function")
                }
            };
        });

        return transformedQuery;
    }

    function manipulateSqlRule(newQuery) {
        const transformRules = (rules) => {
            return rules?.map(rule => {
                if (rule.rules) {
                    return {
                        rules: transformRules(rule.rules)
                    };
                } else {
                    let tempType
                    fields?.filter(f => {
                        if (f.name === rule.field) {
                            tempType = f.type
                        }
                    })
                    return {
                        id: rule.field,
                        field: `c.transactionDetail.${rule.field}`,
                        type: tempType,
                        input: tempType === ("string" || "boolean") ? "text" : "integer",
                        operator: rule.operator,
                        value: rule.value,
                        data: {
                            class: "Customer"
                        }
                    };
                }
            });
        };

        return {
            ...newQuery,
            rules: transformRules(newQuery.rules)
        };
    }

    const handleQueryChange = (newQuery, id, rule, type) => {
        const manipulatedSqlRule = manipulateSqlRule(newQuery)
        const sqlWhere = formatQuery(manipulatedSqlRule, 'sql');
        if (rule === "upgrade") {
            if (type === "existing") {
                setState((prev) => ({
                    ...prev,
                    upgradeRule: prev.upgradeRule.map((ele) => {
                        if (ele.id === id) {
                            return {
                                ...ele,
                                sqlFilter: sqlWhere,
                                existingRule: newQuery
                            }
                        } else return ele
                    })
                }))
            } else {
                setState((prev) => ({
                    ...prev,
                    upgradeRule: prev.upgradeRule.map((ele) => {
                        if (ele.id === id) {
                            return {
                                ...ele,
                                functionRule: newQuery
                            }
                        } else return ele
                    })
                }))
            }
        } else {
            if (type === "existing") {
                setState((prev) => ({
                    ...prev,
                    downgradeRule: prev.downgradeRule.map((ele) => {
                        if (ele.id === id) {
                            return {
                                ...ele,
                                sqlFilter: sqlWhere,
                                existingRule: newQuery
                            }
                        } else return ele
                    })
                }))
            } else {
                setState((prev) => ({
                    ...prev,
                    downgradeRule: prev.downgradeRule.map((ele) => {
                        if (ele.id === id) {
                            return {
                                ...ele,
                                functionRule: newQuery
                            }
                        } else return ele
                    })
                }))
            }
        }
    };

    const handleChange = (e) => {
        setState({ ...state, ...{ [e.target.name]: e.target.value } });
    };

    const onSelectChange = (e, selectFor) => {
        setState({ ...state, [selectFor]: e.value })
    };

    const handleToggle = (e, name) => {
        setState({ ...state, [name]: e })
    }

    const handleAddItem = (type) => {
        if (type === "upgrade") {
            setState((prev) => ({
                ...prev,
                upgradeRule: prev.upgradeRule.length > 0 && [...prev.upgradeRule, { id: prev.upgradeRule[prev.upgradeRule.length - 1].id + 1, existingRule: initialQueryUE, functionRule: initialQueryUF }]
            }))
        } else {
            setState((prev) => ({
                ...prev,
                downgradeRule: prev.downgradeRule.length > 0 && [...prev.downgradeRule, { id: prev.downgradeRule[prev.downgradeRule.length - 1].id + 1 }]
            }))
        }

    }

    const handleDeleteItem = (id, type) => {
        if (type === "upgrade") {
            setState((prev) => ({
                ...prev,
                upgradeRule: prev.upgradeRule.filter((ele) => ele.id !== id)
            }))
        } else {
            setState((prev) => ({
                ...prev,
                downgradeRule: prev.downgradeRule.filter((ele) => ele.id !== id)
            }))
        }

    }

    const onSubmit = async () => {
        const upgradeQuery = manipulateQuery(upgradeRule);
        const downgradeQuery = manipulateQuery(downgradeRule);
        const body = {
            name: name,
            code: null,
            merchantcode: decrypt(localStorage.getItem("Id")),
            alert: alert,
            status: status,
            description: description,
            tags: [],
            schemaCode: transSchema,
            filter: {
                upgradeRule: {
                    durationStatus: upgradeToggle,
                    duration: upgradeDuration,
                    rules: upgradeQuery
                },
                degradeRule: {
                    durationStatus: downgradeToggle,
                    duration: downgradeDuration,
                    rules: downgradeQuery
                }
            }

        }

        setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const res = await createTier(token?.access_token, body);
        if (res.message === "Request processed successfully.") {
            swal({
                position: "center",
                icon: "success",
                title: res?.message,
                showConfirmButton: false,
                timer: 5000,
            });
            setState(initialState)
            setLoader(false);
        } else {
            swal({
                position: "center",
                icon: "error",
                title: res?.message,
                showConfirmButton: false,
                timer: 5000,
            });
            setLoader(false);
        }
    }

    return (
        <div>
            <Header name={"Tiers"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className='dashboard__content'>
                <ValidatorForm onSubmit={onSubmit} className="dashboard__customerViewMain">
                    <div className="dashboard__transactionsCard">
                        <div className='dashboard__customerViewCard'>
                            <div className='row'>
                                <div className='query_builder_box'>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group">
                                                <label>Tiers Name</label>
                                                <TextValidator type="text"
                                                    value={name}
                                                    onChange={handleChange}
                                                    placeholder="Tiers Name"
                                                    className="form-control"
                                                    maxLength="20"
                                                    name="name"
                                                    validators={["required"]}
                                                    errorMessages={["Please enter tier name"]} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <label>
                                                <strong className='d-block mb-3'>Status: </strong>
                                                <Switch name="status" checked={status} onChange={(e) => handleToggle(e, "status")} onColor={status ? "#50BC14" : "#E50000"} onHandleColor="#50BC14" handleDiameter={30} uncheckedIcon={true} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={48} className="react-switch" />
                                            </label>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <label>
                                                <strong className='d-block mb-3'>Alert:</strong>
                                                <Switch name="alert" checked={alert} onChange={(e) => handleToggle(e, "alert")} onColor={alert ? "#50BC14" : "#E50000"} onHandleColor="#50BC14" handleDiameter={30} uncheckedIcon={true} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={48} className="react-switch" />
                                            </label>
                                        </div>
                                        {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group withIcon">
                                                <label>Client</label>
                                                <SelectValidator placeholder="Select Client"
                                                    className="custom-ReactSelect bordered"
                                                    name="client"
                                                    value={selectValueGetter(clientOption, client)}
                                                    options={clientOption}
                                                    onChange={(e) => onSelectChange(e, "client")}
                                                    validators={["required"]}
                                                    errorMessages={["Please select client"]} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group withIcon">
                                                <label>Transaction Schema</label>
                                                <SelectValidator placeholder="Select Client"
                                                    className="custom-ReactSelect bordered"
                                                    name="transactionSchema"
                                                    value={selectValueGetter(schemaOpt, transactionSchema)}
                                                    options={schemaOpt}
                                                    onChange={(e) => onSelectChange(e, "transactionSchema")}
                                                    validators={["required"]}
                                                    errorMessages={["Please select transaction schema"]} />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="row">
                                        {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group withIcon">
                                                <label>Tags</label>
                                                <TextValidator type="text"
                                                    value={tags}
                                                    onChange={handleChange}
                                                    placeholder="Tags"
                                                    className="form-control"
                                                    maxLength="20"
                                                    name="tags"
                                                    validators={["required"]}
                                                    errorMessages={["Please enter tags"]} />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="form-group withIcon">
                                                <TextAreaValidator type="text" maxLength="100" value={description} onChange={handleChange} placeholder="Description" className="form-control" name="description" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className="form-group withIcon">
                                        <div className="dashboard__header">
                                            <div className="dashboard__left">
                                                <h4 id='transction_list'>Upgrade Rule</h4>
                                            </div>
                                            <button type="button" onClick={() => handleAddItem("upgrade")} className='btn btn-success'>+ Add Item</button>
                                        </div>
                                        <div className='dashboard__body border'>
                                            {
                                                upgradeRule?.length > 0 && upgradeRule.map((ele) => {
                                                    return (
                                                        <div className='card p-3 mb-3' key={ele.id}>
                                                            <div className='d-flex justify-content-between align-items-center mb-2'>
                                                                <h6 className='mb-0'>Existing Rule</h6>
                                                                {ele.id > 1 && <button type='button' onClick={() => handleDeleteItem(ele.id, "upgrade")} className='btn btn-danger'>Delete</button>}
                                                            </div>

                                                            <QueryBuilder fields={fields} query={ele.existingRule} onQueryChange={(newQuery) => handleQueryChange(newQuery, ele.id, "upgrade", "existing")}
                                                                controlElements={{
                                                                    addGroup: false,
                                                                    addRule: true,
                                                                    removeRule: true,
                                                                    removeGroup: false,
                                                                }}>
                                                            </QueryBuilder>
                                                            <div className='d-flex justify-content-between align-items-center mb-2 mt-3'>
                                                                <h6 className='mb-0'> Function Rule</h6>
                                                            </div>

                                                            <QueryBuilder fields={fields} query={ele.functionRule} onQueryChange={(newQuery) => handleQueryChange(newQuery, ele.id, "upgrade", "function")}>
                                                            </QueryBuilder>

                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                    <label>
                                                        Duration:
                                                        <Switch name="upgradeToggle" checked={upgradeToggle} onChange={(e) => handleToggle(e, "upgradeToggle")} onColor={upgradeToggle ? "#50BC14" : "#E50000"} onHandleColor="#50BC14" handleDiameter={30} uncheckedIcon={true} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={48} className="react-switch" />
                                                    </label>
                                                </div>
                                                {upgradeToggle && <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                    <div className="form-group withIcon">
                                                        <SelectValidator placeholder="Select Duration"
                                                            className="custom-ReactSelect bordered"
                                                            name="upgradeDuration"
                                                            value={selectValueGetter(durationOpt, upgradeDuration)}
                                                            options={durationOpt}
                                                            onChange={(e) => onSelectChange(e, "upgradeDuration")}
                                                            validators={["required"]}
                                                            errorMessages={["Please select transaction schema"]} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className="form-group withIcon">
                                        <div className="dashboard__header">
                                            <div className="dashboard__left">
                                                <h4 id='transction_list'>DownGrade Rule</h4>
                                            </div>
                                            <button type="button" onClick={() => handleAddItem("downgrade")} className='btn btn-success'>+ Add Item</button>

                                        </div>
                                        <div className='dashboard__body border'>
                                            {
                                                downgradeRule?.length > 0 && downgradeRule.map((ele) => {
                                                    return (
                                                        <div className='card p-3 mb-3' key={ele.id}>
                                                            <div className='d-flex justify-content-between align-items-center mb-2'>
                                                                <h6 className='mb-0'>Existing Rule</h6>
                                                                {ele.id > 1 && <button type='button' onClick={() => handleDeleteItem(ele.id, "downgrade")} className='btn btn-danger'>Delete</button>}
                                                            </div>

                                                            <QueryBuilder fields={fields} query={ele.existingRule} onQueryChange={(newQuery) => handleQueryChange(newQuery, ele.id, "downgrade", "existing")}  >
                                                            </QueryBuilder>
                                                            <div className='d-flex justify-content-between align-items-center mb-2 mt-3'>
                                                                <h6 className='mb-0'> Function Rule</h6>
                                                            </div>

                                                            <QueryBuilder fields={fields} query={ele.functionRule} onQueryChange={(newQuery) => handleQueryChange(newQuery, ele.id, "downgrade", "function")}>
                                                            </QueryBuilder>

                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                    <label>
                                                        Duration:
                                                        <Switch name="downgradeToggle" checked={downgradeToggle} onChange={(e) => handleToggle(e, "downgradeToggle")} onColor={downgradeToggle ? "#50BC14" : "#E50000"} onHandleColor="#50BC14" handleDiameter={30} uncheckedIcon={true} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={48} className="react-switch" />
                                                    </label>
                                                </div>
                                                {downgradeToggle && <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                                    <div className="form-group withIcon">
                                                        <SelectValidator placeholder="Select Duration"
                                                            className="custom-ReactSelect bordered"
                                                            name="downgradeDuration"
                                                            value={selectValueGetter(durationOpt, downgradeDuration)}
                                                            options={durationOpt}
                                                            onChange={(e) => onSelectChange(e, "downgradeDuration")}
                                                            validators={["required"]}
                                                            errorMessages={["Please select transaction schema"]} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='customer_group_form'>
                                <button type='submit' className="btn btn-primary btn-lg">
                                    Create
                                </button>
                                {/* {groupId ? (
                                       <button type='submit' className="btn btn-primary btn-lg">
                                       Update
                                   </button>
                                ) :(
                                    <button type='submit' className="btn btn-primary btn-lg">
                                    Create
                                </button>
                                ) } */}

                            </div>
                        </div>
                    </div>
                </ValidatorForm>
            </div>
        </div>
    )
}

export default Tiers

