import React, { useState, useEffect, useRef } from "react";
import Header from "../../layout/Header";
import { ValidatorForm } from "react-form-validator-core";
import swal from "sweetalert";
import { getWhatsappTemplate, SendGridEmail } from "../../../actions/auth";
import SelectValidator from "../../partial/SelectValidator";
import { getDropdownObject } from "../../../utils/helper";
import { Link } from "react-router-dom";

function WhatsappSend() {
    const [loader, setLoader] = useState(false);
    const [formData, setFormData] = useState({
        version: "",
        numId: "",
        senderNumber: "",
        accountId: "",
        template: "",
        whtspToken: "",
        status: false,
        accountname: ""
    });
    const [option, setOption] = useState([])
    const fileRef = useRef();
    const { accountname } = formData;

    useEffect(() => {
        const WhatsAppAccounts = [
            {
                "whatsappId": "Zepto_cf17dc88-02d6-48d5-ae9f-1da7d2140031",
                "whatsAppAccountTemplateName": "test_custom_template",
                "senderNumber": "kg",
                "NumberId": "450250621507699",
                "version": "oiuyt",
                "token": "kiuy",
                "status": true
            },
            {
                "whatsappId": "Zomato_52af8e9b-d196-452e-a225-35b6a3a4dc30",
                "whatsAppAccountTemplateName": "hello_world",
                "senderNumber": "kg",
                "NumberId": "450250621507699",
                "version": "oiuyt",
                "token": "kiuy",
                "status": true
            },
            {
                "whatsappId": "Zomato_52af8e9b-d196-452e-a225-35b6a3a4dc30",
                "whatsAppAccountTemplateName": "account_statement",
                "senderNumber": "kg",
                "NumberId": "lkjhg",
                "version": "oiuyt",
                "token": "kiuy",
                "status": false
            }
        ]
        const tempAccount = [...new Set(WhatsAppAccounts.map(item => item))];
        const fetchWhatsappTemplate = async () => {
            setLoader(true)
            const token = "EAAGB5iz6IRoBO28onlButYxIg9zamZBcMTOARzFO201MO4docGSe0Qy0om4dh8VHE8ZA5NEA8wWc4kMumhxyMhJZCXLyLC131X42jWZAHj1Qg6UGREXtxWCfLjpHmZBkHoalC94QY2LzKRO08HTlyZA1e5RMbyl7EW1A9fnNPYr4zaFOOcTEi3VpTteBxuslZBlr2fT5gcILuqGvjF888BTRmlBQvgZD"
            try {
                const tempOption = []
                const response = await getWhatsappTemplate(token, tempAccount[0], tempAccount[0])
                console.log(response)
                if (response?.data?.length > 0 && WhatsAppAccounts?.length > 0) {
                    response.data.forEach((ele) => {
                        WhatsAppAccounts.map((item) => {
                            if (ele.name === item.whatsAppAccountTemplateName && item.status === true) {
                                tempOption.push({ value: ele.name, label: ele.name })
                            }
                        })
                    })
                }
                setOption(tempOption)
                setLoader(false)
            } catch (err) {
                setLoader(false)
            }
        }
        fetchWhatsappTemplate()
    }, [])

    const handleTypeChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            accountname: e.value
        }))
    }

    const handleSubmit = async (e) => {
        // if (file) {
        //     e.preventDefault();
        //     setLoader(true);
        //     const token = JSON.parse(localStorage.getItem("token_gen"));
        //     await SendGridEmail(token?.access_token, formData).then((res) => {
        //         if (res.message === "Sending Email SuccessFull") {
        //             swal({
        //                 position: "center",
        //                 icon: "success",
        //                 title: res?.message,
        //                 showConfirmButton: false,
        //                 timer: 5000,
        //             });
        //             setLoader(false);
        //         } else {
        //             swal({
        //                 position: "center",
        //                 icon: "error",
        //                 title: res?.message,
        //                 showConfirmButton: false,
        //                 timer: 5000,
        //             });
        //         }
        //         setEmailData((prev) => ({
        //             ...prev,
        //             selectEmailType: "",
        //             noteData: ""
        //         }))
        //         fileRef.current.value = "";
        //     });
        // } else {
        //     setState({ ...state, error: true, errorMsg: "Please upload a file" });
        // }
        // setLoader(false);
    }

    const handleFile = (event) => {
        setState({ ...state, file: event.target.files[0], error: false });
        const file = event.target.files[0];
        const reader = new FileReader();
        const fileExtension = file?.name?.split(".").pop().toLowerCase();
        reader.onload = (e) => {
            const content = e.target.result;
            const lines = content.split("\n").filter((line) => line.trim() !== "");
            const headers = lines[0].split(",").map((header) => header.trim());
            const data = lines.slice(1).map((line) => {
                const values = line.split(",").map((value) => value.trim());
                let record = {};
                headers.forEach((header, index) => {
                    record[header] = values[index];
                });
                return record;
            });
            setFormData((prevFormData) => ({
                ...prevFormData,
                emails: data,
                fileName: file.name,
            }));
        };
        reader.readAsText(file);
    };

    const [state, setState] = useState({
        file: "",
        error: true,
        errorMsg: "Note:- Only .csv type files are supported.",
    });

    const { file, error, errorMsg } = state;
    return (
        <div>
            <Header name={"Send Whatsapp"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="dashboard__transactionsCard">
                    <div className="dashboard__header">
                        <div className="dashboard__left">
                            <h4 id="transction_list">Send Whatsapp</h4>
                        </div>
                    </div>
                    <ValidatorForm className="dashboard__customerViewMain" onSubmit={(e) => handleSubmit(e)}>
                        <div className="dashboard__customerViewCard">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <div className="form-group withIcon">
                                        <label>Whatsapp Account Name</label>
                                        <SelectValidator placeholder="Select Type" className="custom-ReactSelect bordered" name="accountname" value={accountname ? getDropdownObject(accountname, option) : ""} options={option} onChange={handleTypeChange} validators={["required"]} errorMessages={["Please select account name"]} />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <div className="form-group withIcon">
                                        <div encType="multipart/form-data">
                                            <label>Receiver Number</label>
                                            <input className="form-control" ref={fileRef} placeholder="Uploaded File" type="file" accept=".csv" required onChange={handleFile} />
                                            {error && <span style={{ color: "red" }}>{errorMsg}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label>&nbsp;</label>
                                    <div className="form-group withIcon mt-2 d-flex align-items-center">
                                        <button type="submit" className="btn btn-primary">
                                            Send
                                        </button>
                                        &nbsp;
                                        <Link className="btn btn-success" to="/profile-settings?p13">
                                            <i class="fa-solid fa-gear"></i>&nbsp;Whatsapp
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="customer_group_form d-flex justify-content-end align-items-center">
                                <button type="submit" style={{ marginTop: "10px" }} className="btn btn-primary btn-lg">
                                    Send
                                </button>
                            </div> */}
                        </div>
                    </ValidatorForm>
                </div>
            </div>
        </div>
    );
}

export default WhatsappSend;
