import { getNewAuthToken, isTokenExpired } from "../utils";
import { baseURL } from "../utils/url";
import { CLEAR_CUSTOMER_LIST, GET_CUSTOMER_LIST, SEARCH_CUSTOMER_LIST } from "./type";

export const getCustomer = (token, id, offset, limit,searchValue) => async (dispatch) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `mergedCustomer?id=${id}&offset=${offset}&limit=${limit}&searchValue=${searchValue}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // id: data,
        });
        fetchResponse
            .json()
            .then((res) => {
                if (res) {
                    dispatch({
                        type: GET_CUSTOMER_LIST,
                        payload: res,
                    });
                    return res;
                } else {
                    return null;
                }
            })
            .catch(() => {
                return null;
            });
    } catch (error) {
        return error;
    }
};

export const searchCustomer = (token, value) => async (dispatch) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `searchCustomer?value=${value}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // id: data,
        });
        fetchResponse
            .json()
            .then((res) => {
                if (res) {
                    dispatch({
                        type: SEARCH_CUSTOMER_LIST,
                        payload: { totalCount: res?.totalCount, customers: res?.data },
                    });
                    return { totalCount: res?.totalCount, customers: res?.data };
                } else {
                    return null;
                }
            })
            .catch(() => {
                return null;
            });
    } catch (error) {
        return error;
    }
};

export const clearCustomer = () => async (dispatch) => {
    dispatch({ type: CLEAR_CUSTOMER_LIST });
};
