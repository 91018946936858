import React, { useState, useEffect, useRef } from "react";
import Header from "../../layout/Header";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../../partial/TextValidator";
// import CKEditor from "ckeditor4-react";
import swal from "sweetalert";
import { SendGridEmail } from "../../../actions/auth";
import SelectValidator from "../../partial/SelectValidator";
import { getCustomerPortalSetting } from "../campaigns/services";
import { getDropdownObject, selectValueGetter } from "../../../utils/helper";
import { Link } from "react-router-dom";
import { clearSetting } from "../../../actions/setting";
import parse from "html-react-parser";

function SendGrid() {
    const [loader, setLoader] = useState(false);
    const [editorData, setEditorData] = useState("");
    const [data, setData] = useState();
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        subject: "",
        sendgridApiKey: "",
        fromEmail: "",
        fromName: "",
        emails: [],
        body: "",
        purpose: "",
    });
    const [option, setOption] = useState([])
    const [emailData, setEmailData] = useState({
        selectEmailType: "",
        selectTemplate: "",
        isTestEmail: true,
        noteData: ""
    })
    const fileRef = useRef();
    const { selectEmailType, selectTemplate, isTestEmail, noteData } = emailData


    const { subject, fromEmail, fromName, sendgridApiKey, purpose } = formData;

    useEffect(() => {
        const fetch = async () => {
            setLoader(true)
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const res = await getCustomerPortalSetting(token?.access_token)
            if (res.message === "Settings retrieved successfully") {
                if (res?.data?.EmailTemplateSetting?.length > 0) {
                    let tempOption = []
                    res?.data?.EmailTemplateSetting?.forEach((val) => {
                        if (val.status) {
                            tempOption.push({ value: val.EmailType, label: val.EmailType })
                        }
                    })
                    setOption(tempOption)
                }
                setEmailData((prev) => ({
                    ...prev,
                    selectTemplate: res.data?.EmailTemplateSetting
                }))
            }
            setLoader(false)
        }
        fetch()
    }, [])

 const templateSelection = (value) => {
        if (selectTemplate?.length) {
            const data = selectTemplate.filter((ele) => ele.EmailType === value)
            const { sendgridApiKey, fromEmail, fromName, Body, Subject } = data[0]
            // let body = parse(Body)
            // body = body[0]?.props?.children?.split(" ")?.filter(word => word.includes("@"))
            // setEmailData((prev) => ({
            //     ...prev,
            //     noteData: body
            // }))
            setFormData((prev) => ({
                ...prev,
                sendgridApiKey: sendgridApiKey,
                fromEmail: fromEmail,
                fromName: fromName,
                body: Body,
                subject: Subject,
                purpose: value
            }))
        }
    }
 

    const handleTypeChange = (e) => {
        setEmailData((prev) => ({
            ...prev,
            selectEmailType: e.value
        }))
        templateSelection(e.value)
    }

    const handleSubmit = async (e) => {
        if (file) {
            e.preventDefault();
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            await SendGridEmail(token?.access_token, formData).then((res) => {
                if (res.message === "Sending Email SuccessFull") {
                    swal({
                        position: "center",
                        icon: "success",
                        title: res?.message,
                        showConfirmButton: false,
                        timer: 5000,
                    });
                    setLoader(false);
                } else {
                    swal({
                        position: "center",
                        icon: "error",
                        title: res?.message,
                        showConfirmButton: false,
                        timer: 5000,
                    });
                }
                setEmailData((prev) => ({
                    ...prev,
                    selectEmailType: "",
                    noteData: ""
                }))
                fileRef.current.value = "";
            });
        } else {
            setState({ ...state, error: true, errorMsg: "Please upload a file" });
        }
        setLoader(false);
    };

    const handleChange = (e, type) => {
        if (type === "isTestEmail") {
            return setEmailData((prev) => ({
                ...prev,
                isTestEmail: !emailData.isTestEmail,
            }))
        }
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFile = (event) => {
        setState({ ...state, file: event.target.files[0], error: false });
        const file = event.target.files[0];
        const reader = new FileReader();
        const fileExtension = file?.name?.split(".").pop().toLowerCase();
        reader.onload = (e) => {
            const content = e.target.result;
            const lines = content.split("\n").filter((line) => line.trim() !== "");
            const headers = lines[0].split(",").map((header) => header.trim());
            const data = lines.slice(1).map((line) => {
                const values = line.split(",").map((value) => value.trim());
                let record = {};
                headers.forEach((header, index) => {
                    record[header] = values[index];
                });
                return record;
            });
            setFormData((prevFormData) => ({
                ...prevFormData,
                emails: data,
                fileName: file.name,
            }));
        };
        reader.readAsText(file);
    };

    const [state, setState] = useState({
        file: "",
        error: true,
        errorMsg: "Note:- Only .csv type files are supported.",
    });

    const { file, error, errorMsg } = state;

    const handleEditorChange = (event) => {
        const data = event.editor.getData();
        setEditorData(data);
        setFormData((prevFormData) => ({
            ...prevFormData,
            body: data,
        }));
    };

    return (
        <div>
            <Header name={"Send Email"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="dashboard__transactionsCard">
                    <div className="dashboard__header">
                        <div className="dashboard__left">
                            <h4 id="transction_list">Send Email</h4>
                        </div>
                    </div>
                    <ValidatorForm className="dashboard__customerViewMain" onSubmit={(e) => handleSubmit(e)}>
                        <div className="dashboard__customerViewCard">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <div className="form-group withIcon">
                                        <label>Select Template</label>
                                        <SelectValidator placeholder="Select Type" className="custom-ReactSelect bordered" name="selectEmailType" value={emailData?.selectEmailType ? getDropdownObject(emailData?.selectEmailType, option) : ""} options={option} onChange={handleTypeChange} validators={["required"]} errorMessages={["Please select template"]} />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <div className="form-group withIcon">
                                        <div encType="multipart/form-data">
                                            <label>Email Data</label>
                                            <input className="form-control" ref={fileRef} placeholder="Uploaded File" type="file" accept=".csv" required onChange={handleFile} />
                                            {error && <span style={{ color: "red" }}>{errorMsg}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <label>&nbsp;</label>
                                    <div className="form-group withIcon mt-3">
                                        <Link to="/profile-settings?p12">Create Template</Link>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <div className="person" style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <p className="voucher" style={{ display: "flex", width: "25%", marginBottom: "0", color: "black" }}>
                                            Test Email
                                        </p>
                                        <div className="">
                                            <label className="toggle">
                                                <input type="checkbox" name="isTestEmail"
                                                    checked={emailData?.isTestEmail} onChange={(e) => handleChange(e, "isTestEmail")}
                                                />
                                                <span className="slider"></span>
                                                <span className="labels" data-on="Active" data-off="Inactive"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group withIcon">
                                        {
                                            isTestEmail ? <><label>Test Email</label>
                                                <TextValidator placeholder="Test Email" className="form-control" name="sendgridApiKey" value={sendgridApiKey} onChange={handleChange} validators={["required"]} errorMessages={["Please enter send grid api key"]} />
                                            </> : <><label>Email Data</label>
                                        <div encType="multipart/form-data">
                                            <input className="form-control" placeholder="Uploaded File" type="file" accept=".csv" required
                                            onChange={handleFile} 
                                            />
                                            {error && <span style={{ color: "red" }}>{errorMsg}</span>}
                                        </div>
                                        </>
                                        } 
                                    </div>
                                </div>
                            </div> */}
                            {/* <CKEdsitor data={editorData} onChange={handleEditorChange} /> */}

                            <div className="customer_group_form d-flex justify-content-between align-items-center">
                                <div>
                                    {noteData?.length > 0 && <p className="mb-0"> <strong>Note<sup>*</sup></strong> {noteData.map((ele) => `${ele} `)}</p>}
                                </div>
                                <button type="submit" style={{ marginTop: "10px" }} className="btn btn-primary btn-lg">
                                    Send
                                </button>
                            </div>
                        </div>
                    </ValidatorForm>
                </div>
            </div>
        </div>
    );
}

export default SendGrid;
