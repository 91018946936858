import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, CloseButton } from "react-bootstrap";
import { getTransactionEnquiry } from "../campaigns/services";
import { getDate } from "../../../utils/helper";
import { Link, useLocation } from "react-router-dom";

const TransactionViewModal = (props) => {
    const location = useLocation();
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("token_gen"));

        if (props.show) {
            setLoader(true);
            const response = getTransactionEnquiry(token?.access_token, props.transactionId);

            response
                .then((res) => {
                    setFormData(res.data || {});
                    setLoader(false);
                })
                .catch((err) => {
                    setLoader(false);
                });
        } else setLoader(false);
    }, [props.show, props.transactionId]);
    
    const memoCampaignDetaild = useMemo(() => {
        return (formData?.transactionDetail?.transactionType !== "redeem" && (
            <div className="dashboard__transactionsModalDetails">
                <div className="dashboard__transactionsModalTop">
                    <h2>Campaign Detail</h2>
                </div>
                <div className="dashboard__transactionsModalBottom">
                    {formData?.campaigns?.length ?
                        formData?.campaigns.map((val, i) => (
                            <ul key={i}>
                     

                                 { val?.WalletFor=="Customer" && val?.Type?.toUpperCase() === "POINT" ? 

                                    (val.Value) > 0 ? <div>
                                                   
                                        <li>Earn to :{` ${val.WalletFor}  Wallet ${val.Value} Points`}</li></div> : <div>
                                        <li>Deducted from : {` ${val.WalletFor} Wallet ${val.Value} Points`}</li></div>

                                 : 
                                    <li>
                                        
                                        <span>Earned {val.Type}:</span> {val.VoucherCode}
                                    </li>
                                }
                            </ul>
                        ))
                        :
                        <>Campaign Not qualified</>
                    }
                </div>
            </div>
        ))

    }, [formData])

    return (
        <>
            <Modal show={props.show} onHide={props.close} size="lg" centered>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "20px" }}>Transaction View</Modal.Title>
                    <CloseButton onClick={props.close} variant="white" />
                </Modal.Header>
                <Modal.Body className="dashboard__transactionsModal" style={{ position: "relative", minHeight: "85px" }}>
                    {loader ? (
                        <div className="mainPreloaderMain" style={{ position: "absolute" }}>
                            <div className="mainPreloader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    ) :
                        formData?.transactionId ? (
                            <div className="dashboard__transactionsModalProfile">
                            <div className="dashboard__transactionsModalDetails">
                                <div className="dashboard__transactionsModalBottom">
                                    <ul>
                                        <li>
                                            <span>Transaction Id:</span>{" "}
                                            <span className="characterLimitProperty" title={formData.transactionId}>
                                                {formData.transactionId}
                                            </span>
                                        </li>
                                        <li>
                                            {console.log(formData)}
                                      
                                            <span>Customer Code:</span>
                                            {location?.pathname.includes("customer-view") ? (
                                                <span className="characterLimitProperty" title={formData.transactionId}>
                                                    {formData?.customer[0]?.customercode}
                                                </span>
                                            ) : (
                                                <Link to={`/customer-view/${formData?.customer[0]?.customercode}`} state={{ from: location?.state?.from }}>
                                                    {formData?.customer[0]?.customercode}
                                                </Link>
                                            )}
                                        </li>
                                        {formData.transactionDetail.transactionType === "redeem" && (
                                            <>
                                                <li>
                                                    <span>Redemption Date:</span> {getDate(formData.transactionDetail.transactionDate, "MM-DD-YYYY") !== "Invalid date" ? getDate(formData.transactionDetail.transactionDate, "MM-DD-YYYY") : ""}
                                                </li>
                                                <li>
                                                    <span>Redemption Points:</span> {formData.transactionDetail.values ? formData.transactionDetail.values : formData.transactionDetail.amount}
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            {formData.transactionDetail.transactionType !== "redeem" && (
                                <div className="dashboard__transactionsModalDetails">
                                    <div className="dashboard__transactionsModalTop">
                                        <h2>Campaign Detail</h2>
                                    </div>
                                    <div className="dashboard__transactionsModalBottom">
                                        {Array.isArray(formData?.campaigns) && formData?.campaigns.length ? (
                                            formData?.campaigns.map((val, i) => (
                                                <ul key={i}>
                                                    {val?.Type && val?.Type?.toUpperCase() === "POINT" ? (
                                                        <span>
                                                            <li>
                                                                <span>Campaign Code:</span> {val.Code}
                                                            </li>
                                                            <li>
                                                                <span>Earned Points:</span> {val.Value}
                                                            </li>
                                                        </span>
                                                    ) : val?.Type && val?.Type?.toUpperCase() === "VOUCHER" ? (
                                                        <span>
                                                            <li>
                                                                <span>Campaign Code:</span> {val.Code}
                                                            </li>
                                                            <li>
                                                                <span>Earned Type:</span> {val.Type}
                                                            </li>
                                                        </span>
                                                    ) : (
                                                        <li>
                                                            <span>Deducted Points {val.Type}:</span> {val.Value}
                                                        </li>
                                                    )}
                                                </ul>
                                            ))
                                        ) : (
                                            <>Campaign Not qualified</>
                                        )}
                                    </div>
                                </div>
                            )}
                            {formData.transactionDetail.transactionType !== "redeem" && (
                                <div className="dashboard__transactionsModalDetails">
                                    <div className="dashboard__transactionsModalTop">
                                        <h2>Transaction Detail</h2>
                                    </div>
                                    <div className="dashboard__transactionsModalBottom">
                                        <ul>
                                            {formData.transactionDetail.amount && (
                                                <li>
                                                    <span>Transaction Amount:</span> {formData.transactionDetail.amount ? formData.transactionDetail.amount : "0"}
                                                </li>
                                            )}
                                            {formData.transactionDetail.transactionamount && (
                                                <li>
                                                    <span>Transaction Amount:</span> {formData.transactionDetail.transactionamount ? formData.transactionDetail.transactionamount : "0"}
                                                </li>
                                            )}
                                            {formData.transactionDetail.transactionType ? (
                                                <li>
                                                    <span>Transaction Type:</span> {formData.transactionDetail.transactionType}
                                                </li>
                                            ) : (
                                                <li>
                                                    {" "}
                                                    <span>Transaction Type: purchase</span>{" "}
                                                </li>
                                            )}

                                            {formData.transactionDetail.activityType && (
                                                <li>
                                                    <span>Activity Type:</span> {formData.transactionDetail.activityType}
                                                </li>
                                            )}

                                            {formData.transactionDetail.productType && (
                                                <li>
                                                    <span>Product Type:</span> {formData.transactionDetail.productType}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                        ) : <div style={{
                            textAlign: "center",
                            marginTop: "5%"
                        }}>No Data Found</div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.close}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TransactionViewModal;
