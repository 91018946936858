import React, { useState, useEffect } from "react";
import Header from "../../layout/Header";
import { getMembersipData } from "../../../actions/auth";
import Paginated from "./Paginated";
import { COLUMNS } from "./column";
import { getDate } from "../../../utils/helper";

const Membership = () => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            setLoader(true);
            let tempToken = JSON.parse(localStorage.getItem("token_gen"));
            tempToken = tempToken?.access_token;
            const id = localStorage.getItem("Id");
            await getMembersipData(tempToken, id).then((res)=>{
                console.log(res)
                setFormData(res.data)
                setLoader(false)
            }).catch(()=>{
                setLoader(false) 
            })
            
        
           
        };
        fetch();
    }, []);

    useEffect(() => {
        const tempData = formData;
        console.log(tempData)
        if (tempData) {
            tempData?.map((val, ind) => {
                val.name = val.MembershipTransactionData.name ? val.MembershipTransactionData.name : "-";
                val.email = val.MembershipTransactionData.email ? val.MembershipTransactionData.email :"-";
                val.mobile = val.MembershipTransactionData.mobile ?val.MembershipTransactionData.mobile : "-"
                val.membershipType = val.MembershipTransactionData.membershipType
                val.amount = val.MembershipTransactionData.amount;
                val.date = getDate(val.MembershipTransactionData.TransactionDate, "DD-MMM-YYYY");
                console.log(val.MembershipTransactionData.transactionStatus)
                val.status = val.MembershipTransactionData.transactionStatus === true ? "Yes" : "No"
              
                return val;
            });
        }
        setData(tempData);
    }, [formData]);


    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <Header name={"Membership"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__customersCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4>Membership Details</h4>
                            </div>
                        </div>
                        <div className="dashboard__body">
                            <Paginated data={data || []} columns={COLUMNS} />
                        </div>
                    </div>
                </div>
            
            </div>
        </>
    );
};

export default Membership;
