import React, { useState, useEffect } from "react";
import Header from "../../../layout/Header";
import HeadingSection from "../CustomerHeadingSection.js";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { customerRegistration } from "../../campaigns/services";
import { validateString, extract10DigitNumber, validEmail } from "../../../../utils/helper";
import JSONPretty from "react-json-pretty";
import swal from "sweetalert";
let token;
const CustomerRegAPI = () => {
    const [state, setState] = useState({
        responseCopied: false,
        requestCopied: false,
        loading: false,
        err: false,
        errMsg: "",
        catchErr: false,
        catchErrMsg: "",
        responseText: " ",
        tokenErr: false,
        tokenErrMsg: "",
        tokenSucc: false,
        tokenSuccMsg: "",
        disable: false,
    });
    const requestText = `{   	
		"mobile" : "",
		"name" : ""
    }`;
    const { requestCopied, responseCopied, loading, err, errMsg, catchErr, catchErrMsg, responseText, tokenErr, tokenErrMsg, tokenSucc, tokenSuccMsg, disable } = state;
    useEffect(() => {
        let tokenSuccess, tokenError, catchError, error;
        if (tokenSucc) {
            tokenSuccess = setTimeout(() => {
                setState({ tokenSucc: false, tokenSuccMsg: "" });
            }, 2000);
        }
        if (tokenErr) {
            tokenError = setTimeout(() => {
                setState({ tokenErr: false, tokenErrMsg: "" });
            }, 2000);
        }
        if (err) {
            error = setTimeout(() => {
                setState({ err: false, errMsg: "" });
            }, 2000);
        }
        if (catchErr) {
            catchError = setTimeout(() => {
                setState({ catchErr: false, catchErrMsg: "" });
            }, 2000);
        }
        return () => {
            clearTimeout(tokenSuccess);
            clearTimeout(tokenError);
            clearTimeout(error);
            clearTimeout(catchError);
        };
    }, [tokenErr, tokenSucc, err, catchErr]);

    const handleCopy = (copy) => {
        copy === "request" ? setState({ requestText, responseText, requestCopied: true }) : setState({ responseText, requestText, responseCopied: true });
        setTimeout(() => {
            copy === "request" && setState({ requestText, responseText, requestCopied: "" });
        }, 1000);
        setTimeout(() => {
            copy === "response" && setState({ responseText, requestText, responseCopied: "" });
        }, 1000);
    };

    const handleAccessToken = async () => {
        let tempToken = JSON.parse(localStorage.getItem("token_gen"));
        token = tempToken?.access_token;
        setState({ tokenSucc: true, tokenSuccMsg: "Token generated successfully" });
    };

    const createCustomer = async () => {
        try {
            let body = JSON.parse(document.getElementById("api").value);
            // body.merchantId = localStorage.getItem("merchantcode");
            let { mobile, name, email } = body;
            let tempName = validateString(name);
            let tempMobile = mobile && extract10DigitNumber(mobile);
            let tempEmail = email && validEmail(email);
            if (token) {
                if (mobile && !email) {
                    if (tempMobile !== null) {
                        if (tempName === true) {
                            body = JSON.stringify(body);
                            setState({ loading: true, disable: true });
                            try {
                                const customerReg = await customerRegistration(token, body);

                                setState({ responseText: JSON.stringify(customerReg), loading: false, disable: false });
                            } catch (error) {
                                swal({
                                    title: " Oops!",
                                    text: error,
                                    icon: "error",
                                    button: "oh no!",
                                    timer: 5000,
                                });
                            }
                        } else {
                            setState({ err: true, errMsg: "Please enter a valid name" });
                        }
                    } else {
                        setState({ err: true, errMsg: "Please enter a valid mobile number" });
                    }
                } else if (!mobile && email) {
                    if (tempEmail) {
                        if (tempName === true) {
                            body = JSON.stringify(body);
                            setState({ loading: true, disable: true });
                            try {
                                const customerReg = await customerRegistration(token, body);

                                setState({ responseText: JSON.stringify(customerReg), loading: false, disable: false });
                            } catch (error) {
                                swal({
                                    title: " Oops!",
                                    text: error,
                                    icon: "error",
                                    button: "oh no!",
                                    timer: 5000,
                                });
                            }
                        } else {
                            setState({ err: true, errMsg: "Please enter a valid name" });
                        }
                    } else {
                        setState({ err: true, errMsg: "Please enter a valid mail" });
                    }
                } else {
                    if (tempEmail != null && tempMobile != null) {
                        if (tempName === true) {
                            body = JSON.stringify(body);
                            setState({ loading: true, disable: true });
                            try {
                                const customerReg = await customerRegistration(token, body);

                                setState({ responseText: JSON.stringify(customerReg), loading: false, disable: false });
                            } catch (error) {
                                swal({
                                    title: " Oops!",
                                    text: error,
                                    icon: "error",
                                    button: "oh no!",
                                    timer: 5000,
                                });
                            }
                        } else {
                            setState({ err: true, errMsg: "Please enter a valid name" });
                        }
                    } else {
                        setState({ err: true, errMsg: "Please enter a valid mail or Mobile" });
                    }
                }
            } else {
                setState({ tokenErr: true, tokenErrMsg: "Please generate access token" });
            }
        } catch (error) {
            setState({ ...state, catchErr: true, catchErrMsg: "Invalid JSON format" });
        }
    };
    return (
        <>
            <Header name={"Customers"} />
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__apiPlaygroundBox">
                        <div className="dashboard__apiPlaygroundHeading">
                            <h2>
                                <span className="badge rounded-pill bg-primary">POST</span> Customers
                            </h2>
                        </div>
                        <div className="dashboard__apiPlaygroundBottom">
                            <div className="dashboard__apiPlaygroundEndpoint">
                            <p>
                                    <span>API Endpoint</span>
                                    <Link to="#!">https://apisaasnovus.azurewebsites.net/api/Customer/Registration</Link>
                                </p>
                            </div>
                            <div className="dashboard__apiPlaygroundButton">
                                <button className="btn btn-primary" onClick={handleAccessToken}>
                                    Generate Access Token
                                </button>
                            </div>
                        </div>
                        <div className="dashboard__apiPlaygroundCardMain">
                            {err && (
                                <div className="alert alert-danger" role="alert">
                                    {errMsg}
                                </div>
                            )}
                            {tokenSucc && (
                                <div className="alert alert-success" role="alert">
                                    {tokenSuccMsg}
                                </div>
                            )}
                            {tokenErr && (
                                <div className="alert alert-danger" role="alert">
                                    {tokenErrMsg}
                                </div>
                            )}
                            {catchErr && (
                                <div className="alert alert-danger" role="alert">
                                    {catchErrMsg}
                                </div>
                            )}
                            <div className="dashboard__apiPlaygroundCardHead">
                                <h3>API Simulate</h3>
                            </div>
                            <div className="dashboard__apiPlaygroundCardBox">
                                <div className="dashboard__apiPlaygroundLeft">
                                    <div className="dashboard__requestSection">
                                        <div className="dashboard__header">
                                            <div className="dashboard__left">
                                                <h5>Request</h5>
                                            </div>
                                            <div className="dashboard__right">
                                                <CopyToClipboard text={requestText} onCopy={() => handleCopy("request")}>
                                                    <Link className="btn btn-light" to="">
                                                        {requestCopied ? "Copied" : "Copy"}
                                                    </Link>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        <div className="dashboard__body" id="APICodeBox">
                                            <textarea id="api" defaultValue={requestText}></textarea>
                                        </div>
                                        <div className="dashboard__footer">
                                            <button type="button" className="btn btn-light rounded-circle" disabled={disable ? true : false} title="Get Response" onClick={createCustomer}>
                                                <i className="fa-solid fa-play"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard__apiPlaygroundRight">
                                    <div className="dashboard__responseSection">
                                        <div className="dashboard__header">
                                            <div className="dashboard__left">
                                                <h5>Response</h5>
                                            </div>
                                            <div className="dashboard__right">
                                                <CopyToClipboard text={responseText} onCopy={() => handleCopy("response")}>
                                                    <Link className="btn btn-dark" to="">
                                                        {responseCopied ? "Copied" : "Copy"}
                                                    </Link>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        <div className="dashboard__body" id="APICodeBox">
                                            {loading ? (
                                                <div className="mainPreloaderMain">
                                                    <div className="mainPreloader">
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <JSONPretty id="json-pretty" data={responseText} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <HeadingSection />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomerRegAPI;
