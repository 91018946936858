import React, { useEffect, useState } from 'react'
import { ValidatorForm } from 'react-form-validator-core';
import { getDate } from '../../../utils/helper';
import Header from '../../layout/Header';
import TextValidator from '../../partial/TextValidator';
import { QueryBuilder, formatQuery } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import { getCustomer } from '../../../actions/schema';
import { createCustomer, getCustomerGroupDataBySelectValue, updateCustomer } from '../campaigns/services';
import { decrypt } from '../../../utils/encodingdecoding';
import { getCustomerGroupById, getCustomerGroupList } from '../../../actions/campaigns';
import { COLUMNS } from './customerGroup/column';
import Paginated from './customerGroup/Paginated';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

function CustomerGroup() {

    const [getId, setGetId] = useState()
    const [matchedValue, setMatchedValue] = useState(null);
    const [groupId, setGroupId] = useState()
    const [loader, setLoader] = useState(false);
    const [selectedValues, setSelectedvalues] = useState({});
    const [selectedValue, setSelectedvalue] = useState({});
    const [selectedFields, setSelectedFields] = useState({});
    const [selectedField, setSelectedField] = useState({});
    const [selectedData, setSelectedData] = useState({});
    const [queryData, setQueryData] = useState({})
    const [response, setResponse] = useState()
    const [setList, setFormList] = useState([])
    const [formData, setFormData] = useState({
        name: "",
        sql: ""
    });
    const { name, sql } = formData;
    const onChange = (e) => {
        setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
    };
    const [data, setData] = useState([]);

    const fields = data.map((item) => {
        if (item?.type === 'Enumeration') {
            return {
                name: item?.key,
                label: item?.fielddisplayname,
                type: item?.type,
                valueEditorType: 'select',
                values: [
                    { value: '', label: 'Select', key: 'select-option' },
                    ...item?.enum.map((i, index) => ({ value: i, label: i, key: index }))
                ]
            };
        }
        else {
            return {
                name: item?.key,
                label: item?.fielddisplayname,
                type: item?.type,
                
             
            };
        }
    });

 
    const initialQuery = {
        combinator: 'and',
        rules: [],
    };
    const [query, setQuery] = useState(initialQuery);


    useEffect(() => {
        const querys = JSON.stringify(query, null, 2)
        setQueryData(querys)
    }, [query])

    const updateData = () => {
        setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        getCustomerGroupList(token?.access_token).then((res) => {
            setResponse(res?.data)
            setLoader(false)
        });
    }

    useEffect(() => {
        updateData()
    }, [])

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        const sqlWhere = formatQuery(query, 'sql');
        const rulesAsObject = JSON.parse(queryData);
        // const rulesData = rulesAsObject.rules.map((rule) => {
        //     return {
        //         field: rule.field,
        //         operator: rule.operator,
        //         value: rule.value,
        //     };
        // });


        const body = {
            customerGroupName: name,
            merchantId: [decrypt(localStorage.getItem("merchantcode"))],
            customerGroupId: groupId,
            id: getId,
            advanceSqlQuery: sql,
            filter: {
                sqlFilter: sqlWhere,
                jsonData: query
            },
        };
        const token = JSON.parse(localStorage.getItem("token_gen"));
        if (getId) {
            const response = await updateCustomer(token?.access_token, body);
            updateData();
            if (response?.message === "Request processed successfully.") {
                swal({
                    position: "center",
                    icon: "success",
                    title: "Customer Group Updated",
                    showConfirmButton: false,
                    timer: 2000,
                });
                setGroupId()
                setFormData({
                    name: "",
                    sql: "",
                });
                setMatchedValue()
                setQuery(initialQuery);
            } else {
                swal({
                    position: "center",
                    icon: "error",
                    title: response?.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        } else {
            const response = await createCustomer(token?.access_token, body);
            if (response?.message === "Request processed successfully.") {
                swal({
                    position: "center",
                    icon: "success",
                    title: "Customer Group Created",
                    showConfirmButton: false,
                    timer: 2000,
                });
                updateData();
                setFormData({
                    name: "",
                    sql: "",
                });
                setMatchedValue()
                setQuery(initialQuery);
            } else {
                swal({
                    position: "center",
                    icon: "error",
                    title: response?.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        }
        setLoader(false);
    };

    const handleLinkClick = (id, customerGroupId) => {
        setGetId(id)
        setGroupId(customerGroupId)
        setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        getCustomerGroupById(token?.access_token, id).then((res) => {
            window.scrollTo(0, 0);
            const { customerGroupName, advanceSqlQuery, filter } = res.data;
            const queryData = filter.jsonData;
            setFormData((prevState) => ({
                ...prevState,
                name: customerGroupName,
                sql: advanceSqlQuery,
            }));
            setQuery({
                combinator: queryData.condition,
                rules: queryData.rules
            });
        });
        setLoader(false)
    };

    useEffect(() => {
        const tempData = response;
        if (tempData) {
            let serialNumber = 1;
            tempData?.map((val, ind) => {
                val.serialNumber = serialNumber++;
                val.customergroupname = val.customerGroupName;
                val.createDate = getDate(val.createdDate, "DD-MMM-YYYY");
                val.action = (
                    <Link
                        className="edit_btn"
                        state={val.id}
                        onClick={() => handleLinkClick(val.id, val.customerGroupId)}
                        style={{ display: "flex", justifyContent: "center" }}
                        csvalue="Edit"
                    >
                        <i className="fa-solid fa-pen"></i>
                        <div className="show_div">Edit</div>
                    </Link>
                );
                return val;
            });
        }
        setFormList(tempData);
    }, [response]);

    const span_cbox = {
        display: "inline-block",
        marginBottom: "5px"
    }

    const fetch = async () => {
        try {
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const tedata = await getCustomer(token?.access_token);


            const propertiesWithDisplayNameAndType = Object.entries(tedata.schema.properties)
                .filter(([key, property]) => property.isCustomerGroup === true)
                .map(([key, property]) => ({

                    key,
                    fielddisplayname: property.fielddisplayname,
                    type: property.fielddatatype,
                    enum: property.enum
                }));
            setData(propertiesWithDisplayNameAndType);
            setLoader(false);
        } catch (error) {
            setLoader(false);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    const handleQueryChange = (newQuery) => {
        if (newQuery.rules.length > 0) {
            newQuery.rules.forEach((ruleArray) => {

                const selectedFieldName = ruleArray.field;
                const selectedFieldNames = ruleArray.value


                const selectedFieldInfos = fields.find((field) => {
                    if (field.values && Array.isArray(field.values)) {

                        return field.values.some((item) => item?.value === selectedFieldNames);

                    } else {

                        return false;
                    }
                });


                const selectedFieldInfo = fields.find((field) => field.name === selectedFieldName);
                setSelectedvalues(selectedFieldNames)
                setSelectedField(selectedFieldInfo);
                setSelectedFields(selectedFieldInfos)
                setSelectedvalue(selectedFieldName);
            })
        }
        setQuery(newQuery);
    };


    useEffect(() => {

        if (selectedValue) {

            const token = JSON.parse(localStorage.getItem("token_gen"));
            const merchantCode = localStorage.getItem("Id");
            const customerGroupValue = selectedValue;

            getCustomerGroupDataBySelectValue(token?.access_token, merchantCode, customerGroupValue)
                .then((responseCustomer) => {

                    setSelectedData(responseCustomer.data);

                })
                .catch((error) => {
                    setLoader(false);
                });
        }
    }, [selectedValue]);


    useEffect(() => {

        if (selectedValue && selectedValue && selectedData && selectedData[selectedValue]) {
            setMatchedValue(selectedData[selectedValue]);
        } else if (selectedValues && selectedValues && selectedData && selectedData[selectedValues]) {
            setMatchedValue(selectedData[selectedValues]);
        }
        else {
            setMatchedValue(null);
        }
    }, [selectedData, selectedValue, selectedValues]);






    return (
        <div>
            <Header name={"Customer Group"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className='dashboard__content'>
                <ValidatorForm onSubmit={onSubmit} className="dashboard__customerViewMain">
                    <div className="dashboard__transactionsCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                {groupId ? (
                                     <h4 id='transction_list'>Update Customer Group</h4>
                                ) :(
                                    <h4 id='transction_list'>Create Customer Group</h4>
                                ) }
                               
                            </div>
                        </div>
                        <div className='dashboard__customerViewCard'>
                            <div className='row'>
                                <div className="query_builder_row">
                                    <div className='query_builder_box'>
                                        <div className="form-group">
                                            <span style={span_cbox}>Customer Group Name</span>
                                            <TextValidator className="form-control" validators={["required"]} value={name} placeholder="Name" name="name" maxLength="50" errorMessages={"Customer group name is required"} onChange={onChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className="form-group withIcon">
                                        <QueryBuilder fields={fields} query={query} onQueryChange={handleQueryChange}>

                                        </QueryBuilder>
                                        {matchedValue &&
                                            <b>Total Count: {matchedValue}</b>
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className='customer_group_form'>
                            {groupId ? (
                                       <button type='submit' className="btn btn-primary btn-lg">
                                       Update
                                   </button>
                                ) :(
                                    <button type='submit' className="btn btn-primary btn-lg">
                                    Create
                                </button>
                                ) }
                            
                            </div>
                        </div>
                    </div>
                </ValidatorForm>
                <div className="dashboard__customersCard">
                    <div className="dashboard__header">
                        <div className="dashboard__left">
                            <h4 id='transction_list'>Customer Group List</h4>
                        </div>
                    </div>
                    <div className="dashboard__body">
                        <Paginated data={setList ? setList : []} columns={COLUMNS} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerGroup

