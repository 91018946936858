import React, { useState, useRef, useEffect } from "react";
import { logo, eyeSeen, eyeUnseen, eyeSeenFocus, eyeUnseenFocus } from "../../Image/Index";
import { Link, useNavigate } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import { Internal_Token, Token_Generator } from "../../actions/auth";
import { signing } from "../../actions/authAction";
import TextValidator from "../partial/TextValidator";
import AuthenticationSliderLeft from "./common/AuthenticationSliderLeft";
import { decrypt, encrypt } from "../../utils/encodingdecoding";
import { connect } from "react-redux";
import SelectValidator from "../partial/SelectValidator";
import { selectValueGetter } from "../../utils/helper";

import ReCAPTCHA from "react-google-recaptcha";

function SignIn({ signing, authReducer }) {
    const formRef = useRef();
    const [incorrectPasswordAttempts, setIncorrectPasswordAttempts] = useState(JSON.parse(sessionStorage.getItem("incorrectPasswordAttempts")) || 0);
    const [error, setError] = useState(null);
    const [showReCAPTCHA, setShowReCAPTCHA] = useState(false);
    const recaptchaRef = useRef();
    const navigate = useNavigate();
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [pswdFocus, setPswdFocus] = useState(false);
    const [type, setType] = useState("");
    const [formData, setFormData] = useState({
        email: "",
        passwordhash: "",
    });
    const [response, setResponse] = useState({});
    const [message, setMessage] = useState(false);
    const [loader, setLoader] = useState(false);

    const { email, passwordhash } = formData;

    useEffect(() => {
        const res = authReducer.authData;
        if (res.message === "Valid Login Details" && sessionStorage.getItem("token") && localStorage.getItem("token_rare")) {
            setResponse({});
            let body = {
                clientid: res.clientid,
                grant_type: "client_credentials",
                clientsecret: res.clientsecret,
            };
            const response = Internal_Token(body);
            response.then((respo) => {
                localStorage.setItem("token_gen", JSON.stringify(respo));
                localStorage.setItem("Id", res.merchantcode);
                localStorage.setItem("clientid", res.clientid);
                localStorage.setItem("schemaId", res.schemaId);
                localStorage.setItem("merchantcode", res.merchantcode);
                localStorage.setItem("clientsecret", res.clientsecret);
                localStorage.setItem("schemaName", res.schemaName);
                localStorage.setItem("email", res.email);
                if (decrypt(res.email) === "deepak@clavax.com" || decrypt(res.email) === "deepikab@clavax.com" || decrypt(res.email) === "tarunm@clavax.com") {
                    localStorage.setItem("saasemail", res.email);
                    localStorage.setItem("superAdmin", true);
                }
                localStorage.setItem("loginKey", res.loginKey);
                localStorage.setItem("name", res.name);
                localStorage.setItem("dashboard", "UD");
                sessionStorage.setItem("dashboard", "UD");
                localStorage.setItem("cardStatus", res.cardStatus);
                localStorage.setItem("plan", res.plan);
                localStorage.setItem("moduleAccess", res.moduleAccess);
                localStorage.setItem("packageNameList", encrypt(JSON.stringify(res.packageNameList)));
                sessionStorage.removeItem("incorrectPasswordAttempts");
                localStorage.setItem("firstlogin", "Y");
                if (res.isLogin.toLowerCase() === "y") {
                    navigate("/intro");
                } else if (decrypt(res.cardStatus) === "Active") {
                    res.packageNameList.filter((item) => {
                        if (item.id == decrypt(res.plan)) {
                            if (item.plan == "Trail") {
                                navigate("/intro");
                            } else {
                                const moduleAccess = localStorage.getItem("moduleAccess") && JSON.parse(decrypt(localStorage.getItem("moduleAccess")));
                                const emailDash = moduleAccess.some((ele) => ele.moduleName === "Email Dashboard" && ele.isEnable === true);
                                if (emailDash) {
                                    navigate("/email-dashboard");
                                } else {
                                    navigate("/dashboard");
                                }
                            }
                        }
                    });
                } else {
                    navigate("/intro");
                }
                setMessage(false);
                setLoader(false);
            });
        } else if (res.message == "Invalid Login Details" && res.message) {
            var emailset = sessionStorage.getItem("emailAttempts");
            if (emailset == email) {
                setIncorrectPasswordAttempts(incorrectPasswordAttempts + 1);
                sessionStorage.setItem("incorrectPasswordAttempts", incorrectPasswordAttempts + 1);
            } else {
                sessionStorage.setItem("emailAttempts", email);
                setIncorrectPasswordAttempts(1);
                sessionStorage.setItem("incorrectPasswordAttempts", 1);
            }

            localStorage.setItem("token", JSON.stringify(false));
            setMessage(true);
            setLoader(false);
            setResponse(response);
        } else if (res.message !== "Valid Login Details" && res.message) {
            localStorage.setItem("token", JSON.stringify(false));
            setMessage(true);
            setLoader(false);
            setResponse(response);
        }
    }, [authReducer.authData]); // eslint-disable-line

    useEffect(() => {
        if (incorrectPasswordAttempts >= 5) {
            setShowReCAPTCHA(true);
            sessionStorage.setItem("incorrectPasswordAttempts", incorrectPasswordAttempts.toString());
        } else {
        }
    }, [incorrectPasswordAttempts]);

    // const onChange = (e) => setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
    const onChange = (e) => {
        setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
        setMessage(false);
    };
    const onSubmit = async () => {
        if (showReCAPTCHA) {
            const recaptchaValue = recaptchaRef.current.getValue();

            if (!recaptchaValue) {
                setError("Select A Captcha");
                return;
            }
        }
        const body = {
            email: encrypt(email),
            passwordhash: encrypt(passwordhash),
        };
        setLoader(true);
        const token = await Token_Generator();
     
        await signing(token?.access_token, body);
        // setLoader(false);
    };
    const handleChange = (value) => {
        // console.log(recaptchaRef.current.getValue())
    };

    return (
        <section className="section signUpPage">
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="signUpPage__main">
                <AuthenticationSliderLeft />
                <div className="signUpPage__right">
                    <div className="signUpPage__heading">
                        <figure>
                            <img src={logo} alt="logo" />
                        </figure>
                        <h1>Sign In</h1>
                        <h4>Sign in your account to access Novus Loyalty features</h4>
                    </div>
                    <div className="signUpPage__form">
                        <ValidatorForm className="signUpPage__formSection" ref={formRef} onSubmit={(e) => onSubmit(e)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        {/* <label className="form-label">Email ID</label> */}
                                        <TextValidator className="form-control" placeholder="Enter Email ID" name="email" maxLength="50" value={email} type="email" onChange={onChange} validators={["required"]} errorMessages={["Email address is required."]} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group withIcon">
                                        {/* <label className="form-label">Password</label> */}
                                        <TextValidator placeholder="Enter Password" className="form-control" name="passwordhash" value={passwordhash} type={isRevealPwd ? "text" : "password"} onChange={onChange} validators={["required"]} errorMessages={["Password is required."]} />
                                        <span className="passwordToggle">{pswdFocus ? <img className="onFocus" title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? "/Images/eyeUnseenFocus.png" : "/Images/eyeSeenFocus.png"} alt="eye" onClick={() => setIsRevealPwd((prevState) => !prevState)} /> : <img  title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? "/Images/eyeUnseen.png" : "/Images/eyeSeen.png"} alt="eye" onClick={() => setIsRevealPwd((prevState) => !prevState)} />}</span>
                                        {message && !response?.message ? <span className="textValidatorError">{authReducer.authData.message}</span> : <span className="textValidatorError">{response?.message}</span>}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">{showReCAPTCHA && <ReCAPTCHA style={{ display: "inline-block" }} theme="dark" ref={recaptchaRef} sitekey={process.env.REACT_APP_GOOGLE_KEY} onChange={handleChange} />}</div>
                                    {error && <div style={{ color: "red" }}>{error}</div>}
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <p className="text-end">
                                            <Link to="/forgot-password">Forgot Password ?</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary btn-lg w-100">
                                            Sign in
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p>
                                            Don't have an account? <Link to="/signup">Sign Up</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <p className="text-end">
                                            <Link to="/contactus">Contact Us</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ValidatorForm>
                    </div>
                </div>
            </div>
        </section>
    );
}
const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
});
const mapDispatchToProps = {
    signing,
};

// export default SignIn;
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
