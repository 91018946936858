
export const EMAIL_TEMPLATE_COLUMN = [
    {
        Header: "Subject",
        Footer: "Subject",
        accessor: "Subject",
        sticky: "left",
    },
    {
        Header: "From Name",
        Footer: "From Name",
        accessor: "fromName",
    },
    {
        Header: "From Email",
        Footer: "From Email",
        accessor: "fromEmail",
    },
    {
        Header: "Send Grid APIkey",
        Footer: "Send Grid APIkey",
        accessor: "sendgridApiKey",
    },
    {
        Header: "Email Type",
        Footer: "Email Type",
        accessor: "EmailType",
    },
    {
        Header: "Body",
        Footer: "Body",
        accessor: "action",
    },
    {
        Header: "Status",
        Footer: "Status",
        accessor: "status",
    },
    {
        Header: "Delete",
        Footer: "Delete",
        accessor: "delete",
    },
];
