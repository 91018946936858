import React, { useEffect, useLayoutEffect, useState } from "react";
import Header from "../layout/Header";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Paginated from "./Paginated";
import { COLUMNS } from "./column";
import { connect } from "react-redux";
import { voucherTemplateDetail, clearVoucherTemplate } from "../../actions/voucher";
import VoucherViewModal from "./VoucherViewModal";
import UploadVoucherModal from "./UploadVoucherModal";
import { GenerateVoucher, DownloadVoucher } from "../../actions/auth";
import swal from "sweetalert";

const VoucherTemplate = ({ voucherTemplateDetail, VoucherTemplate, clearVoucherTemplate }) => {
    const [formData, setFormData] = useState([]);
    const [showLogin, setShowLogin] = useState(false);
    const [voucherCode, setVoucherCode] = useState("");
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(true);
        clearVoucherTemplate();
        const token = JSON.parse(localStorage.getItem("token_gen"));
        voucherTemplateDetail(token?.access_token)
            .then(() => {
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
            });
    }, []);

    const handleUploadViewModal = (data) => {
        setShowUploadModal(true);
        setVoucherCode(data);
    };
    const handleViewModal = (data) => {
        setShowLogin(true);
        setVoucherCode(data);
    };
    const handleShowUploadModal = (isShow, title) => {
        setShowUploadModal(isShow);
        if (title === "Voucher are processing to get status refresh the page.") {
            swal({
                position: "center",
                icon: "success",
                title: title,
                showConfirmButton: false,
                timer: 5000,
            });
        } else {
            swal({
                position: "center",
                icon: "error",
                title: title,
                showConfirmButton: false,
                timer: 5000,
            });
        }
        // window.location.reload();
    };

    const handleCreateVoucher = async (val) => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const body = { code: val.code, value: parseInt(val.voucherValue), generateVoucher: val.numberOfVouchers };
        let response = await GenerateVoucher(token?.access_token, body);
        if (response.id) {
            swal({
                position: "center",
                icon: "success",
                title: "Refresh the page your voucher has been created successfully.",
                showConfirmButton: false,
                timer: 5000,
            });
        } else {
            swal({
                position: "center",
                icon: "error",
                title: response.message,
                showConfirmButton: false,
                timer: 5000,
            });
        }
    };
    const handleGenerateFile = async (code) => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const body = { code: code };
        let response = await DownloadVoucher(token?.access_token, body);
        if (response.message === "Voucher are processing to get status refresh the page.") {
            swal({
                position: "center",
                icon: "success",
                title: "Your file is generating please refresh the page after some time.",
                showConfirmButton: false,
                timer: 5000,
            });
        } else {
            swal({
                position: "center",
                icon: "error",
                title: response.message,
                showConfirmButton: false,
                timer: 5000,
            });
        }
    };
    useLayoutEffect(() => {
        const tempData = VoucherTemplate?.dataList;
        if (tempData) {
            tempData?.map((val, ind) => {
                return (
                    (val.name = val.name),
                    (val.templateCode = val.code),
                    (val.generatedVouchers = (
                        <>
                            No. of Generated Vouchers {val.lastGenratedNumberOfVoucher}
                            <br />
                            No. of Used Vouchers {val.usedVoucherCount}
                            <br />
                            No. of Un-used Vouchers {val.lastGenratedNumberOfVoucher - val.usedVoucherCount}
                        </>
                    )),
                    (val.totalRedemption = val.totalRedemption),
                    (val.tagline = val.tagline),
                    (val.voucherGeneratedType = val.voucherGeneratedType ? (
                        "Generated"
                    ) : val?.importVoucher?.length ? (
                        <>
                            Imported
                            <br /> <p style={{ color: "#ff6a00" }}>Total Last Imported Voucher Count {val.importVoucher[0].totalCount}</p>
                            <p style={{ color: "green" }}>Last Succesfull Imported Voucher Count {val.importVoucher[0].successVoucherCount}</p>
                            <p style={{ color: "red" }}>Last Unsuccessful Imported Voucher Count {val.importVoucher[0].unSuccessVoucherCount}</p>
                        </>
                    ) : (
                        <>
                            Imported
                            <br /> <p style={{ color: "#ff6a00" }}>Total Last Imported Voucher Count 0</p>
                            <p style={{ color: "green" }}>Last Succesfull Imported Voucher Count 0</p>
                            <p style={{ color: "red" }}>Last Unsuccessful Imported Voucher Count 0</p>
                        </>
                    )),
                    (val.tags = "N/A"),
                    (val.isactive = val.isActive === true ? "Active" : "Inactive"),
                    (val.action = (
                        <>
                            <Link to="" state={{ voucherCode: val.code }} className="edit_btn" csvalue="View Details" onClick={() => handleViewModal(val.code)}>
                                <i className="fa-solid fa-eye"></i>
                                <div className="show_div">View Details</div>
                            </Link>
                            <Link to="/voucher" state={val.code} className="edit_btn" csvalue="Edit">
                                <i className="fa-solid fa-pen"></i>
                                <div className="show_div">Edit</div>
                            </Link>
                            {val.voucherGeneratedType === "Generated" ? (
                                <Link to="" onClick={() => handleCreateVoucher(val)} className="edit_btn" csvalue="Generate Voucher">
                                    <i className="fa-solid fa-file"></i>
                                    <div className="show_div">Generate Voucher(s)</div>
                                </Link>
                            ) : (
                                <Link to="" onClick={() => handleUploadViewModal(val.code)} className="edit_btn" csvalue="Upload Voucher">
                                    <i className="fa-solid fa-upload"></i>
                                    <div className="show_div">Upload Voucher(s)</div>
                                </Link>
                            )}
                            {val.lastGenratedNumberOfVoucher > 0 && (
                                <Link to="" state={{ voucherCode: val.code }} onClick={() => handleGenerateFile(val.code)} className="edit_btn" csvalue="Generate File">
                                    <i className="fa-solid fa-file"></i>
                                    <div className="show_div">Generate File</div>
                                </Link>
                            )}
                            {val.generatePath != "" && (
                                <Link to={val.generatePath} className="edit_btn" csvalue="Generate File">
                                    <i className="fa-solid fa-download"></i>
                                    <div className="show_div">Download File</div>
                                </Link>
                            )}
                        </>
                    ))
                );
            });
        }
        setFormData(tempData);
    }, [VoucherTemplate]);

    return (
        <>
            <Header name={"Voucher Template"} />
            {VoucherTemplate?.show && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="dashboard__heading">
                                <div className="dashboard__headingLeft">
                                    <div className="dashboard__headingContent">
                                        <Link to="/voucher">
                                            <Button id="voucher" className="btn btn-primary btn-lg">
                                                Create Voucher Template
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard__customersCard">
                            <div className="dashboard__header">
                                <div className="dashboard__left">
                                    <h4 id="voucher_details">Voucher Template Details</h4>
                                    {showLogin && <VoucherViewModal show={showLogin} close={() => setShowLogin(false)} voucherCode={showLogin && voucherCode} />}
                                    {showUploadModal && <UploadVoucherModal show={showUploadModal} close={() => setShowUploadModal(false)} voucherCode={showUploadModal && voucherCode} handleShowUploadModal={handleShowUploadModal} />}
                                </div>
                            </div>
                            <div className="dashboard__body">{VoucherTemplate?.show ? "" : <Paginated data={formData ? formData : []} columns={COLUMNS} />}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    VoucherTemplate: state.VoucherTemplate,
});
const mapDispatchToProps = {
    voucherTemplateDetail,
    clearVoucherTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherTemplate);