import React, { useState, useEffect, memo } from "react";
import Header from "../layout/Header";
import { connect } from "react-redux";
import { COLUMNS } from "./transactionsList/column";
import Paginated from "./transactionsList/Paginated";
import { getDate, selectValueGetter } from "../../utils/helper";
import { Link } from "react-router-dom";
import { importIcon } from "../../Image/Index";
import TransactionViewModal from "./transactionsList/TransactionViewModal";
import { getTransaction, clearTransaction } from "../../actions/transaction";
import { getVoucherTemplate } from "./campaigns/services";
import { ValidatorForm } from "react-form-validator-core";
import SelectValidator from "../partial/SelectValidator";
 
const TransactionsList = memo(({ getTransaction, Transaction, clearTransaction }) => {
    const [data, setData] = useState([]);
    const [valueselect, setValueSelect] = useState("all");
    const [loader, setLoader] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [transactionId, setTransactionId] = useState("");
    const [voucher, setVoucher] = useState([]);
    const [option] = useState([
        { label: "All", value: "all" },
        { label: "Qualified", value: "qualified" },
        { label: "Not Qualified", value: "notQualified" },
    ]);

    const [filterOption, setFilterOption] = useState("all");


    const unPeople = (e) => {
        setValueSelect(e.value);
        setFilterOption(e.value);
    };

    const onSubmit = () => { };
    useEffect(() => {
        let tempPageIndex = JSON.parse(localStorage.getItem("TransPageIndex"));
        if (tempPageIndex === 1) {
            fetch();
        }
        // eslint-disable-next-line
    }, []);
 
    useEffect(() => {
        const ShortData = async () => {
           
            const tempData = Transaction?.data?.transactions;
            await tempData && tempData.map((val) => {
                val.isQualified = val?.transactionDetail?.transactionType === "purchase" ? val?.qualifiedCampaign?.length ? "Yes" : "No" : "-";
                val.transactionType = val?.transactionDetail?.transactionType;
                var type = ""
                var total = 0
                val?.qualifiedCampaign?.map((value) => {
                    if (value.type === "voucher") {
                        total = value.voucherTemplateCode;
                        type = value.type
                    } 
                })
                const positiveValues = val?.qualifiedCampaign?.filter(item => item.value > 0);
                const totalPositiveValue = positiveValues?.reduce((acc, curr) => acc + curr.value, 0);
                voucher?.voucherTemplate?.length && voucher?.voucherTemplate?.map((data) => { if (total == data.code) total = data.voucherValue })
                val.earning = type === "voucher" ? "Earn voucher worth " + total : totalPositiveValue ? totalPositiveValue + " " + "point" : "-"
             
           
                return (
                    (val.customercode = val.customer.customercode),
                    val.transactionId,
                    ((val.transactionProcessedDate = getDate(val.transactionProcessedDate, "DD-MMM-YYYY")),
                        (val.action = (
                            <Link to="" state={{ transactionId: val.customer.customercode }} onClick={() => handleViewModal(val.transactionId)}>
                                <i className="fa-solid fa-eye"></i> View Details
                            </Link>
                        )))
                );
 
            })

            if (tempData) {
                await tempData.sort(function (a, b) {
                    if (a.transactionProcessedDate === null) {
                        return 1;
                    } else if (b.transactionProcessedDate === null) {
                        return -1;
                    }
                    const dateA = new Date(a.transactionProcessedDate);
                    const dateB = new Date(b.transactionProcessedDate);
                    if (dateA < dateB) {
                        return 1;
                    } else if (dateA > dateB) {
                        return -1;
                    } else {
                        const timeA = dateA.getTime();
                        const timeB = dateB.getTime();
                        return timeB - timeA;
                    }
                });
            }
            tempData?.map((val) => {
                const filteredData =
                filterOption === "all"
                    ? tempData
                    : tempData.filter((val) => {
                        if (filterOption === "qualified") {
                            return val?.transactionDetail?.transactionType === "purchase" && val?.qualifiedCampaign?.length;
                        } 
                        else if (filterOption === "notQualified") {
                            return val?.transactionDetail?.transactionType === "purchase" && val?.qualifiedCampaign?.length == 0
                        }
                        return false;
                    });
                    setData(filteredData);
                  
               
            })
       
               
            
        };
      
        ShortData();
    }, [Transaction,voucher,filterOption]);
 
    const fetch = async () => {
        setLoader(true);
        await clearTransaction();
        const token = JSON.parse(localStorage.getItem("token_gen"));
        let id = localStorage.getItem("Id");
        await getTransaction(token?.access_token, id, 1, 500);
        await getVoucherTemplate(token?.access_token)
            .then((res) => {
                setVoucher({ ...voucher, voucherTemplate: res.data });
            })
        setLoader(false);
    };
    const handleViewModal = (data) => {
        setShowLogin(true);
        setTransactionId(data);
    };
    // eslint-disable-next-line
 
    return (
        <>
            <Header name={"Transaction"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__transactionsCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4>Transaction Data</h4>
                                {showLogin && <TransactionViewModal show={showLogin} close={() => setShowLogin(false)} transactionId={showLogin && transactionId} />}
                            </div>
                            <div className="dashboard__right">
                                <Link to="/uploading-existing-datas" id="upload_transction" state={{ prevPath: "/transactions-list", from: "transaction" }} className="btn btn-default">
                                    <img src={importIcon} alt="" /> Upload Transaction
                                </Link>
                            </div>
                        </div>
                        <div className="dashboard__body">
                            <p>No existing Transaction data.</p>
                            <p>You can add the data by uploading a CSV file containing the details.</p>
                        </div>
                    </div>
 
                    <div className="dashboard__transactionsCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4 id="transction_list">Transaction List</h4>
                            </div>
                        </div>
                        <div className="dashboard__body">
                        <div className="dashboard__heading" style={{ justifyContent: "end", marginBottom: "0" }}>
                                <div className="dashboard__headingRight">
                                    <div className="form-group">
                                        <ValidatorForm onSubmit={onSubmit}>
                                            <SelectValidator placeholder="Select" className="" name="choice" value={selectValueGetter(option, valueselect)} options={option} onChange={unPeople} />
                                        </ValidatorForm>
                                    </div>
                                </div>
                            </div>
                            <Paginated data={data} columns={COLUMNS} setLoader={setLoader} loader={loader} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});
 
const mapStateToProps = (state) => ({
    Transaction: state.Transaction,
});
const mapDispatchToProps = {
    getTransaction,
    clearTransaction,
};
 
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsList);