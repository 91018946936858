export const SMSCOLUMN = [
    {
        Header: "Type",
        Footer: "Type",
        accessor: "type",
        sticky: "left",
    },
    {
        Header: "Send Grid APIkey",
        Footer: "Send Grid APIkey",
        accessor: "sendgridApiKey",
    },
    {
        Header: "Title",
        Footer: "Title",
        accessor: "title",
    },
    {
        Header: "Header",
        Footer: "Header",
        accessor: "header",
    },
    {
        Header: "Status",
        Footer: "Status",
        accessor: "status",
    },
    {
        Header: "Delete",
        Footer: "Delete",
        accessor: "delete",
    },
];
